import { useEffect, useState } from 'react';
import {
  Breadcrumb,
  Button,
  Col,
  Divider,
  Image,
  Layout,
  Modal,
  Row,
  Space,
  Tag,
  Typography
} from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { Header } from 'component';
import './styles.scss';
import { deleteUser, getUserDetail, resetPassword } from 'services';
import { CheckOutlined, ExclamationCircleOutlined } from '@ant-design/icons/lib/icons';
import { DateFormatter } from 'utils';
import { IMAGE_PLACEHOLDER } from '../../constants';

export default function DetailUser() {
  const { id } = useParams();
  const history = useHistory();
  const [data, setData] = useState();

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    const result = await getUserDetail(id);

    setData(result.data?.data);
  }

  function openModalEdit() {
    Modal.confirm({
      title: 'Lakukan edit profile?',
      content: 'Apakah kamu yakin ingin melakukan edit profile?',
      onOk() {
        history.push(`/users/update/${id}`);
      }
    });
  }

  function openModalVerify() {
    Modal.confirm({
      title: 'Lakukan verifikasi KTP?',
      content: 'Apakah kamu yakin ingin melakukan verifikasi KTP',
      onOk() {
        history.push(`/users/verify/${id}`);
      }
    });
  }
  function openModalReset() {
    Modal.confirm({
      title: 'Lakukan Reset Password?',
      content: 'Apakah kamu yakin ingin melakukan reset password?',
      onOk() {
        resetPassword(data?.email)
          .then(() => {
            Modal.success({
              title: 'Password berhasil direset',
              content: `Kamu berhasil mengreset password`,
              afterClose: () => fetchData()
            });
          })
          .catch(() => {
            Modal.error({
              title: 'Passowrd gagal direset',
              content: `Password tidak bisa dihapus, mohon coba lagi nanti`
            });
          });
      }
    });
  }

  function openModalDelete() {
    Modal.confirm({
      title: 'Hapus user ini?',
      content:
        'Apakah kamu yakin ingin menghapus user ini? User yang telah dihapus tidak dapat dikembalikan lagi.',
      icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
      onOk() {
        deleteUser(id).then(() => {
          history.push('/users');
        });
      }
    });
  }

  return (
    <Layout className="container">
      <Header label={data?.name ?? '-'} allowBack />
      <Breadcrumb className="breadcrumb-container">
        <Breadcrumb.Item>Kelola User</Breadcrumb.Item>
        <Breadcrumb.Item>{data?.name ?? '-'}</Breadcrumb.Item>
      </Breadcrumb>
      <Layout.Content className="content-container">
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Row>
            <Col span={4}>
              <Image
                width={150}
                height={185}
                src={data?.profile_picture ?? ''}
                fallback={IMAGE_PLACEHOLDER}
              />
            </Col>
            <Col span={16}>
              <Row gutter={[8, 8]}>
                {Boolean(data?.is_official) && (
                  <Col span={24}>
                    <Tag color={'blue'}>Akun Official</Tag>
                  </Col>
                )}
                <Col span={12}>
                  <Typography.Text type="secondary">ID Pengguna</Typography.Text>
                  <Typography.Title level={4} className="profile-detail">
                    {data?.id ?? '-'}
                  </Typography.Title>
                </Col>
                <Col span={12}>
                  <Typography.Text type="secondary">Email</Typography.Text>
                  <Typography.Title level={4} className="profile-detail">
                    {data?.email ?? '-'}
                  </Typography.Title>
                </Col>
                <Col span={12}>
                  <Typography.Text type="secondary">Nama Lengkap</Typography.Text>
                  <Typography.Title level={4} className="profile-detail">
                    {data?.name ?? '-'}
                  </Typography.Title>
                </Col>
                <Col span={12}>
                  <Typography.Text type="secondary">Role</Typography.Text>
                  <Typography.Title level={4} className="profile-detail">
                    {data?.role?.name ?? '-'}
                  </Typography.Title>
                </Col>
                <Col span={12}>
                  <Typography.Text type="secondary">Nomor Telepon</Typography.Text>
                  <Typography.Title level={4} className="profile-detail">
                    {data?.phone?.match(/.{1,4}/g).join(' ') ?? '-'}
                  </Typography.Title>
                </Col>
                <Col span={12}>
                  <Typography.Text type="secondary">Member Sejak</Typography.Text>
                  <Typography.Title level={4} className="profile-detail">
                    {DateFormatter(data?.created_at)}
                  </Typography.Title>
                </Col>
              </Row>
            </Col>
            <Col span={4} style={{ display: 'flex', justifyContent: 'end' }}>
              <Button type="primary" size="large" onClick={openModalEdit}>
                Edit Profil
              </Button>
            </Col>
          </Row>
        </Space>
        <Row style={{ marginBlock: 20 }}>
          <Col
            span={24}
            style={{ display: 'flex', alignItems: 'end', justifyContent: 'space-between' }}>
            <Typography.Title level={5} style={{ margin: 0 }}>
              Verifikasi KTP
              {Boolean(data?.is_verified_user) && (
                <CheckOutlined
                  style={{
                    marginLeft: '.5rem',
                    padding: 2,
                    borderRadius: 16,
                    backgroundColor: '#66AD20',
                    color: 'white'
                  }}
                />
              )}
            </Typography.Title>
            <Button
              type="primary"
              size="large"
              disabled={!data?.ktp_picture || data?.is_verified_user}
              onClick={openModalVerify}>
              Verifikasi Sekarang
            </Button>
          </Col>
          <Divider style={{ borderTopColor: '#C5C5C5', marginBlock: 10 }} />
          <span>
            {data?.ktp_picture
              ? data?.is_verified_user
                ? 'Kartu Identitas telah terverifikasi'
                : 'User ini telah meminta verifikasi KTP'
              : 'User ini belum melakukan verifikasi KTP'}
          </span>
        </Row>
        {data?.ktp_picture && <Image width={400} src={data?.ktp_picture} />}
        <Row style={{ marginBlock: 20 }}>
          <Col
            span={24}
            style={{ display: 'flex', alignItems: 'end', justifyContent: 'space-between' }}>
            <Typography.Title level={5} style={{ margin: 0 }}>
              Reset Password
            </Typography.Title>
            <Button type="primary" size="large" onClick={openModalReset}>
              Reset Password
            </Button>
          </Col>
          <Divider style={{ borderTopColor: '#C5C5C5', marginBlock: 10 }} />
          <span>Ubah password dengan yang baru</span>
        </Row>
        <Row style={{ marginBlock: 20 }}>
          <Col
            span={24}
            style={{ display: 'flex', alignItems: 'end', justifyContent: 'space-between' }}>
            <Typography.Title level={5} style={{ margin: 0 }} type="danger">
              Hapus User
            </Typography.Title>
            <Button size="large" danger onClick={openModalDelete}>
              Hapus User
            </Button>
          </Col>
          <Divider style={{ borderTopColor: '#C5C5C5', marginBlock: 10 }} />
          <span>User yang telah dihapus tidak dapat dikembalikan lagi.</span>
        </Row>
      </Layout.Content>
    </Layout>
  );
}
