import { PlusOutlined, SearchOutlined } from '@ant-design/icons/lib/icons';
import { Breadcrumb, Button, Input, Layout, Modal, Row, Space, Table, Tag } from 'antd';
import { Header } from 'component';
import { DEFAULT_CATEGORIES_ID_ARRAY } from 'constants';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { deleteCategory, getCategories } from 'services';
import { DateFormatter } from 'utils';
import './styles.scss';

export default function ManageCategory() {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState('');

  const [data, setData] = useState();

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    const result = await getCategories();

    setData(result.data?.data);
  }

  function openModal(data) {
    Modal.confirm({
      title: 'Hapus kategori ini?',
      content: `Apakah kamu yakin ingin menghapus kategori ${data.name}?`,
      onOk() {
        deleteCategory(data.id)
          .then(() => {
            Modal.success({
              title: 'Kategori berhasil dihapus',
              content: `Kamu berhasil menghapus kategori ${data.name}`,
              afterClose: () => fetchData()
            });
          })
          .catch(() => {
            Modal.error({
              title: 'Kategori gagal dihapus',
              content: `Kategori tidak bisa dihapus, mohon coba lagi nanti`
            });
          });
      }
    });
  }

  const columns = [
    {
      title: 'Nama Kategori',
      dataIndex: 'name',
      key: 'name',
      render: (data, row) => (
        <Tag
          color={row?.color}
          style={{ borderRadius: 12, border: 0, padding: '4px 12px', fontWeight: 600 }}>
          {data ?? '-'}
        </Tag>
      )
    },
    {
      title: 'Tanggal Pembuatan',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date) => DateFormatter(date)
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'action',
      width: '250px',
      render: (id, row) =>
        !DEFAULT_CATEGORIES_ID_ARRAY.some((value) => value == id) && (
          <Space size="small">
            <Button
              type="primary"
              style={{ backgroundColor: '#FFBE18', borderColor: '#FFBE18' }}
              onClick={() => history.push(`categories/update/${id}`)}>
              Edit
            </Button>
            <Button type="primary" danger onClick={() => openModal(row)}>
              Hapus
            </Button>
          </Space>
        )
    }
  ];

  return (
    <Layout className="container">
      <Header label="Kelola Kategori Berita" />
      <Breadcrumb className="breadcrumb-container">
        <Breadcrumb.Item>Kelola Kategori Berita</Breadcrumb.Item>
      </Breadcrumb>
      <Layout.Content className="content-container">
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Row>
            <Input
              style={{ width: '25%' }}
              placeholder="Cari kategori..."
              prefix={<SearchOutlined />}
              onChange={(e) => setFilter(e.target.value)}
            />
            <Button
              style={{ marginLeft: 'auto', color: '#1890FF', borderColor: '#1890FF' }}
              icon={<PlusOutlined />}
              onClick={() => history.push('/categories/add')}>
              Tambah kategori
            </Button>
          </Row>
          <Table
            columns={columns}
            pagination={{
              position: ['bottomLeft'],
              current: page,
              pageSize: limit,
              total: data?.total ?? 0,
              onChange: (page) => setPage(page),
              onShowSizeChange: (x, size) => setLimit(size)
            }}
            dataSource={data?.filter((value) => (filter ? value.name?.includes(filter) : value))}
          />
        </Space>
      </Layout.Content>
    </Layout>
  );
}
