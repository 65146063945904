export const PROVINCE = [
  { id: 911, nama: 'Aceh' },
  { id: 912, nama: 'Sumatera Utara' },
  { id: 913, nama: 'Sumatera Barat' },
  { id: 914, nama: 'Riau' },
  { id: 915, nama: 'Jambi' },
  { id: 916, nama: 'Sumatera Selatan' },
  { id: 917, nama: 'Bengkulu' },
  { id: 918, nama: 'Lampung' },
  { id: 919, nama: 'Kepulauan Bangka Belitung' },
  { id: 921, nama: 'Kepulauan Riau' },
  { id: 931, nama: 'DKI Jakarta' },
  { id: 932, nama: 'Jawa Barat' },
  { id: 933, nama: 'Jawa Tengah' },
  { id: 934, nama: 'DI Yogyakarta' },
  { id: 935, nama: 'Jawa Timur' },
  { id: 936, nama: 'Banten' },
  { id: 951, nama: 'Bali' },
  { id: 952, nama: 'Nusa Tenggara Barat' },
  { id: 953, nama: 'Nusa Tenggara Timur' },
  { id: 961, nama: 'Kalimantan Barat' },
  { id: 962, nama: 'Kalimantan Tengah' },
  { id: 963, nama: 'Kalimantan Selatan' },
  { id: 964, nama: 'Kalimantan Timur' },
  { id: 965, nama: 'Kalimantan Utara' },
  { id: 971, nama: 'Sulawesi Utara' },
  { id: 972, nama: 'Sulawesi Tengah' },
  { id: 973, nama: 'Sulawesi Selatan' },
  { id: 974, nama: 'Sulawesi Tenggara' },
  { id: 975, nama: 'Gorontalo' },
  { id: 976, nama: 'Sulawesi Barat' },
  { id: 981, nama: 'Maluku' },
  { id: 982, nama: 'Maluku Utara' },
  { id: 991, nama: 'Papua Barat' },
  { id: 994, nama: 'Papua' }
];

export const WORLD = [
  { id: 0, nama: 'Bulgaria' },
  { id: 1, nama: 'Botswana' },
  { id: 2, nama: 'Guinea' },
  { id: 3, nama: 'Barbados' },
  { id: 4, nama: 'Senegal' },
  { id: 5, nama: 'Guadeloupe' },
  { id: 6, nama: 'French Guiana' },
  { id: 7, nama: 'Mali' },
  { id: 8, nama: 'Namibia' },
  { id: 9, nama: 'Ukraine' },
  { id: 10, nama: 'Cook Islands' },
  { id: 11, nama: 'Georgia' },
  { id: 12, nama: 'North Macedonia' },
  { id: 13, nama: 'Afghanistan' },
  { id: 14, nama: 'Saint Helena, Ascension and Tristan da Cunha' },
  { id: 15, nama: 'Venezuela' },
  { id: 16, nama: 'São Tomé and Príncipe' },
  { id: 17, nama: 'Hungary' },
  { id: 18, nama: 'Solomon Islands' },
  { id: 19, nama: 'DR Congo' },
  { id: 20, nama: 'Brazil' },
  { id: 21, nama: 'Mozambique' },
  { id: 22, nama: 'Central African Republic' },
  { id: 23, nama: 'Norfolk Island' },
  { id: 24, nama: 'Gibraltar' },
  { id: 25, nama: 'Cayman Islands' },
  { id: 26, nama: 'Laos' },
  { id: 27, nama: 'Turkey' },
  { id: 28, nama: 'South Africa' },
  { id: 29, nama: 'Sri Lanka' },
  { id: 30, nama: 'Nigeria' },
  { id: 31, nama: 'Turks and Caicos Islands' },
  { id: 32, nama: 'Sweden' },
  { id: 33, nama: 'Faroe Islands' },
  { id: 34, nama: 'Eswatini' },
  { id: 35, nama: 'Philippines' },
  { id: 36, nama: 'Romania' },
  { id: 37, nama: 'Dominica' },
  { id: 38, nama: 'Kyrgyzstan' },
  { id: 39, nama: 'South Sudan' },
  { id: 40, nama: 'United States Virgin Islands' },
  { id: 41, nama: 'Antarctica' },
  { id: 42, nama: 'Benin' },
  { id: 43, nama: 'Uzbekistan' },
  { id: 44, nama: 'Pakistan' },
  { id: 45, nama: 'Rwanda' },
  { id: 46, nama: 'Malta' },
  { id: 47, nama: 'El Salvador' },
  { id: 48, nama: 'Peru' },
  { id: 49, nama: 'Italy' },
  { id: 50, nama: 'South Georgia' },
  { id: 51, nama: 'Vietnam' },
  { id: 52, nama: 'Zambia' },
  { id: 53, nama: 'New Zealand' },
  { id: 54, nama: 'Somalia' },
  { id: 55, nama: 'Monaco' },
  { id: 56, nama: 'Kenya' },
  { id: 57, nama: 'Bouvet Island' },
  { id: 58, nama: 'Sudan' },
  { id: 59, nama: 'United States Minor Outlying Islands' },
  { id: 60, nama: 'Vanuatu' },
  { id: 61, nama: 'Fiji' },
  { id: 62, nama: 'Austria' },
  { id: 63, nama: 'Saint Lucia' },
  { id: 64, nama: 'Aruba' },
  { id: 65, nama: 'Algeria' },
  { id: 66, nama: 'Seychelles' },
  { id: 67, nama: 'Netherlands' },
  { id: 68, nama: 'Micronesia' },
  { id: 69, nama: 'Sint Maarten' },
  { id: 70, nama: 'Tonga' },
  { id: 71, nama: 'Montenegro' },
  { id: 72, nama: 'Wallis and Futuna' },
  { id: 73, nama: 'Ireland' },
  { id: 74, nama: 'Antigua and Barbuda' },
  { id: 75, nama: 'Gambia' },
  { id: 76, nama: 'Isle of Man' },
  { id: 77, nama: 'Kiribati' },
  { id: 78, nama: 'Togo' },
  { id: 79, nama: 'Anguilla' },
  { id: 80, nama: 'Brunei' },
  { id: 81, nama: 'Bhutan' },
  { id: 82, nama: 'Germany' },
  { id: 83, nama: 'Denmark' },
  { id: 84, nama: 'Chad' },
  { id: 85, nama: 'United Arab Emirates' },
  { id: 86, nama: 'Sierra Leone' },
  { id: 87, nama: 'Luxembourg' },
  { id: 88, nama: 'Svalbard and Jan Mayen' },
  { id: 89, nama: 'Belarus' },
  { id: 90, nama: 'Gabon' },
  { id: 91, nama: 'Tunisia' },
  { id: 92, nama: 'Cyprus' },
  { id: 93, nama: 'American Samoa' },
  { id: 94, nama: 'Hong Kong' },
  { id: 95, nama: 'Haiti' },
  { id: 96, nama: 'Mauritania' },
  { id: 97, nama: 'Colombia' },
  { id: 98, nama: 'Comoros' },
  { id: 99, nama: 'Tuvalu' },
  { id: 100, nama: 'Iran' },
  { id: 101, nama: 'San Marino' },
  { id: 102, nama: 'Poland' },
  { id: 103, nama: 'Ghana' },
  { id: 104, nama: 'Guam' },
  { id: 105, nama: 'British Indian Ocean Territory' },
  { id: 106, nama: 'Kazakhstan' },
  { id: 107, nama: 'Liberia' },
  { id: 108, nama: 'Republic of the Congo' },
  { id: 109, nama: 'Falkland Islands' },
  { id: 110, nama: 'Bangladesh' },
  { id: 111, nama: 'Tanzania' },
  { id: 112, nama: 'Finland' },
  { id: 113, nama: 'Indonesia' },
  { id: 114, nama: 'Jersey' },
  { id: 115, nama: 'Panama' },
  { id: 116, nama: 'Myanmar' },
  { id: 117, nama: 'Turkmenistan' },
  { id: 118, nama: 'Heard Island and McDonald Islands' },
  { id: 119, nama: 'Curaçao' },
  { id: 120, nama: 'Equatorial Guinea' },
  { id: 121, nama: 'Albania' },
  { id: 122, nama: 'Egypt' },
  { id: 123, nama: 'Martinique' },
  { id: 124, nama: 'Bermuda' },
  { id: 125, nama: 'Cocos (Keeling) Islands' },
  { id: 126, nama: 'North Korea' },
  { id: 127, nama: 'Qatar' },
  { id: 128, nama: 'Zimbabwe' },
  { id: 129, nama: 'Bahrain' },
  { id: 130, nama: 'Cape Verde' },
  { id: 131, nama: 'Marshall Islands' },
  { id: 132, nama: 'Ivory Coast' },
  { id: 133, nama: 'Kuwait' },
  { id: 134, nama: 'Macau' },
  { id: 135, nama: 'Liechtenstein' },
  { id: 136, nama: 'Saudi Arabia' },
  { id: 137, nama: 'Nicaragua' },
  { id: 138, nama: 'Russia' },
  { id: 139, nama: 'Moldova' },
  { id: 140, nama: 'Croatia' },
  { id: 141, nama: 'Suriname' },
  { id: 142, nama: 'Samoa' },
  { id: 143, nama: 'Jordan' },
  { id: 144, nama: 'Iraq' },
  { id: 145, nama: 'Argentina' },
  { id: 146, nama: 'Tajikistan' },
  { id: 147, nama: 'Switzerland' },
  { id: 148, nama: 'Spain' },
  { id: 149, nama: 'Taiwan' },
  { id: 150, nama: 'India' },
  { id: 151, nama: 'Norway' },
  { id: 152, nama: 'Estonia' },
  { id: 153, nama: 'Dominican Republic' },
  { id: 154, nama: 'Malawi' },
  { id: 155, nama: 'Saint Kitts and Nevis' },
  { id: 156, nama: 'Mauritius' },
  { id: 157, nama: 'Niger' },
  { id: 158, nama: 'Canada' },
  { id: 159, nama: 'Yemen' },
  { id: 160, nama: 'South Korea' },
  { id: 161, nama: 'Trinidad and Tobago' },
  { id: 162, nama: 'Andorra' },
  { id: 163, nama: 'Pitcairn Islands' },
  { id: 164, nama: 'Guinea-Bissau' },
  { id: 165, nama: 'Djibouti' },
  { id: 166, nama: 'Cameroon' },
  { id: 167, nama: 'Australia' },
  { id: 168, nama: 'French Southern and Antarctic Lands' },
  { id: 169, nama: 'Singapore' },
  { id: 170, nama: 'Réunion' },
  { id: 171, nama: 'Papua New Guinea' },
  { id: 172, nama: 'Greece' },
  { id: 173, nama: 'France' },
  { id: 174, nama: 'Saint Vincent and the Grenadines' },
  { id: 175, nama: 'Uruguay' },
  { id: 176, nama: 'Syria' },
  { id: 177, nama: 'Bolivia' },
  { id: 178, nama: 'Grenada' },
  { id: 179, nama: 'Lithuania' },
  { id: 180, nama: 'Åland Islands' },
  { id: 181, nama: 'Jamaica' },
  { id: 182, nama: 'Guernsey' },
  { id: 183, nama: 'Czechia' },
  { id: 184, nama: 'Belgium' },
  { id: 185, nama: 'Serbia' },
  { id: 186, nama: 'Guatemala' },
  { id: 187, nama: 'Timor-Leste' },
  { id: 188, nama: 'Bosnia and Herzegovina' },
  { id: 189, nama: 'Eritrea' },
  { id: 190, nama: 'Nauru' },
  { id: 191, nama: 'Paraguay' },
  { id: 192, nama: 'Lebanon' },
  { id: 193, nama: 'Morocco' },
  { id: 194, nama: 'Honduras' },
  { id: 195, nama: 'Slovenia' },
  { id: 196, nama: 'United Kingdom' },
  { id: 197, nama: 'Saint Martin' },
  { id: 198, nama: 'Azerbaijan' },
  { id: 199, nama: 'Costa Rica' },
  { id: 200, nama: 'Kosovo' },
  { id: 201, nama: 'China' },
  { id: 202, nama: 'Palau' },
  { id: 203, nama: 'Portugal' },
  { id: 204, nama: 'Chile' },
  { id: 205, nama: 'Cambodia' },
  { id: 206, nama: 'Latvia' },
  { id: 207, nama: 'Western Sahara' },
  { id: 208, nama: 'Angola' },
  { id: 209, nama: 'United States' },
  { id: 210, nama: 'Mayotte' },
  { id: 211, nama: 'Caribbean Netherlands' },
  { id: 212, nama: 'Vatican City' },
  { id: 213, nama: 'Mexico' },
  { id: 214, nama: 'Belize' },
  { id: 215, nama: 'Thailand' },
  { id: 216, nama: 'Tokelau' },
  { id: 217, nama: 'British Virgin Islands' },
  { id: 218, nama: 'Israel' },
  { id: 219, nama: 'Maldives' },
  { id: 220, nama: 'Uganda' },
  { id: 221, nama: 'Cuba' },
  { id: 222, nama: 'Iceland' },
  { id: 223, nama: 'Mongolia' },
  { id: 224, nama: 'Malaysia' },
  { id: 225, nama: 'Nepal' },
  { id: 226, nama: 'Saint Pierre and Miquelon' },
  { id: 227, nama: 'Japan' },
  { id: 228, nama: 'Saint Barthélemy' },
  { id: 229, nama: 'Northern Mariana Islands' },
  { id: 230, nama: 'Burundi' },
  { id: 231, nama: 'Greenland' },
  { id: 232, nama: 'Libya' },
  { id: 233, nama: 'Armenia' },
  { id: 234, nama: 'Ecuador' },
  { id: 235, nama: 'French Polynesia' },
  { id: 236, nama: 'Burkina Faso' },
  { id: 237, nama: 'Oman' },
  { id: 238, nama: 'Guyana' },
  { id: 239, nama: 'Ethiopia' },
  { id: 240, nama: 'Madagascar' },
  { id: 241, nama: 'Christmas Island' },
  { id: 242, nama: 'Puerto Rico' },
  { id: 243, nama: 'Slovakia' },
  { id: 244, nama: 'Lesotho' },
  { id: 245, nama: 'Montserrat' },
  { id: 246, nama: 'New Caledonia' },
  { id: 247, nama: 'Niue' },
  { id: 248, nama: 'Palestine' },
  { id: 249, nama: 'Bahamas' }
];
