import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { Button, Card, Form, Input, Layout, Modal } from 'antd';
import { login } from 'services';
import './styles.scss';

export default function Login() {
  // eslint-disable-next-line no-unused-vars
  const [x, setCookies] = useCookies();
  const [loading, setLoading] = useState(false);

  function validate(values) {
    if (!values.email) {
      Modal.error({
        title: 'Error',
        content: 'Email harus diisi',
        maskClosable: true
      });
    } else if (!values.password) {
      Modal.error({
        title: 'Error',
        content: 'Password harus diisi',
        maskClosable: true
      });
    } else {
      return true;
    }
  }

  async function handleLogin(values) {
    if (validate(values)) {
      setLoading(true);
      const result = await login(values);

      if (result.data?.data?.access_token) {
        setCookies('user', { token: result.data?.data?.access_token }, { path: '/' });
        window.location.reload();
      } else {
        Modal.error({
          title: 'Email/Password anda salah',
          content: 'Pastikan email & password yang anda masukkan benar',
          maskClosable: true
        });
        setLoading(false);
      }
    }
  }

  return (
    <>
      <Layout
        style={{
          minHeight: '100vh',
          background: '#3F8FE7',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <img src={require('assets/img/login-shape-1.png')} className="bubble1" />
        <img src={require('assets/img/login-shape-1.png')} className="bubble2" />
        <img src={require('assets/img/login-shape-1.png')} className="bubble3" />
        <div className="login-title">KABELINS</div>
        <div className="login-subtitle">ADMIN DASHBOARD</div>
        <Card
          style={{ borderRadius: '20px', marginTop: '70px' }}
          bodyStyle={{ width: '542px', minHeight: 367, display: 'flex' }}>
          <Form
            layout="vertical"
            initialValues={{ remember: false }}
            onFinish={handleLogin}
            style={{
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'column',
              justifyContent: 'space-around'
            }}>
            <Form.Item label="Email Address" name="email">
              <Input className="login-input" placeholder="Enter your email address" />
            </Form.Item>
            <Form.Item label="Password" name="password">
              <Input className="login-input" type="password" placeholder="Enter your password" />
            </Form.Item>
            <Form.Item>
              {
                <Button
                  style={{ minHeight: 60 }}
                  type="primary"
                  block
                  shape="round"
                  size="large"
                  htmlType="submit"
                  loading={loading}>
                  Login
                </Button>
              }
            </Form.Item>
          </Form>
        </Card>
      </Layout>
    </>
  );
}
