import { PlusOutlined, SearchOutlined } from '@ant-design/icons/lib/icons';
import { Breadcrumb, Button, Input, Layout, Modal, Row, Space, Table } from 'antd';
import { Header } from 'component';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { deleteKonsultasiHukum, getKonsultasiHukum } from 'services';
import './styles.scss';

export default function ManageKonsultasiHukum() {
  const history = useHistory();
  const [dataSource, setDatasource] = useState([]);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    const { data } = await getKonsultasiHukum();
    setDatasource(data?.data);
    setTableData(data?.data);
  }

  function openModal(data) {
    Modal.confirm({
      title: 'Hapus pengacara ini?',
      content: `Apakah kamu yakin ingin menghapus ${data?.name}?`,
      onOk() {
        deleteKonsultasiHukum(data?.id)
          .then(() => {
            Modal.success({
              title: 'Pengacara berhasil dihapus',
              content: `Kamu berhasil menghapus ${data?.name}`,
              afterClose: () => fetchData()
            });
          })
          .catch(() => {
            Modal.error({
              title: 'Pengacara gagal dihapus',
              content: `Pengacara tidak bisa dihapus, mohon coba lagi nanti`
            });
          });
      }
    });
  }

  function handleFilter(value) {
    setTableData(
      dataSource?.filter((data) =>
        String(data.name).toLowerCase().includes(String(value).toLowerCase())
      )
    );
  }

  const columns = [
    {
      title: 'Nama',
      dataIndex: 'name',
      width: 300,
      key: 'name'
    },
    {
      title: 'Nomor Telepon',
      dataIndex: 'phone',
      key: 'phone'
    },
    {
      title: 'Alamat',
      dataIndex: 'address',
      width: 300,
      key: 'address'
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'action',
      width: '250px',
      render: (id, row) => (
        <Space size="small">
          <Button
            type="primary"
            style={{ backgroundColor: '#FFBE18', borderColor: '#FFBE18' }}
            onClick={() => history.push(`/konsultasi-hukum/update/${id}`)}>
            Edit
          </Button>
          <Button type="primary" danger onClick={() => openModal(row)}>
            Hapus
          </Button>
        </Space>
      )
    }
  ];

  return (
    <Layout className="container">
      <Header label="Kelola Konsultasi Hukum" />
      <Breadcrumb className="breadcrumb-container">
        <Breadcrumb.Item>Kelola Konsultasi Hukum</Breadcrumb.Item>
      </Breadcrumb>
      <Layout.Content className="content-container">
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Row>
            <Input
              style={{ width: '25%' }}
              placeholder="Cari nama"
              prefix={<SearchOutlined />}
              onChange={(e) => handleFilter(e?.target?.value)}
            />
            <Button
              style={{ marginLeft: 'auto', color: '#1890FF', borderColor: '#1890FF' }}
              icon={<PlusOutlined />}
              onClick={() => history.push('/konsultasi-hukum/add')}>
              Tambah Konsultasi Hukum
            </Button>
          </Row>
          <Table
            columns={columns}
            pagination={{
              position: ['bottomLeft']
            }}
            dataSource={tableData}
          />
        </Space>
      </Layout.Content>
    </Layout>
  );
}
