import {
  ExclamationCircleOutlined,
  PlusOutlined,
  SearchOutlined
} from '@ant-design/icons/lib/icons';
import { Breadcrumb, Button, Input, Layout, Modal, Row, Space, Table } from 'antd';
import { Header } from 'component';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { deleteUser, getUsers } from 'services';
import './styles.scss';

export default function ManageUser() {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState('');
  let debounce = undefined;

  const [data, setData] = useState();

  useEffect(() => {
    debounce = setTimeout(() => {
      fetchData();
    }, 300);

    return () => clearTimeout(debounce);
  }, [page, limit, filter]);

  async function fetchData() {
    const result = await getUsers({ page, limit, email: filter });

    setData(result.data?.data);
  }

  function openModal(id) {
    Modal.confirm({
      title: 'Hapus user ini?',
      content:
        'Apakah kamu yakin ingin menghapus user ini? User yang telah dihapus tidak dapat dikembalikan lagi.',
      icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
      onOk() {
        deleteUser(id).then(() => {
          fetchData();
        });
      }
    });
  }

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Nama Lengkap',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (role) => <>{role.name}</>
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'action',
      width: '250px',
      render: (id) => (
        <Space size="small">
          <Button type="primary" onClick={() => history.push(`/users/${id}`)}>
            Lihat Detail
          </Button>
          <Button type="primary" danger onClick={() => openModal(id)}>
            Hapus
          </Button>
        </Space>
      )
    }
  ];

  return (
    <Layout className="container">
      <Header label="Kelola User" />
      <Breadcrumb className="breadcrumb-container">
        <Breadcrumb.Item>Kelola User</Breadcrumb.Item>
      </Breadcrumb>
      <Layout.Content className="content-container">
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Row>
            <Input
              style={{ width: '25%' }}
              placeholder="Cari Email User"
              prefix={<SearchOutlined />}
              onChange={(e) => setFilter(e.target.value)}
            />
            <Button
              style={{ marginLeft: 'auto', color: '#1890FF', borderColor: '#1890FF' }}
              icon={<PlusOutlined />}
              onClick={() => history.push('/users/add')}>
              Tambah User
            </Button>
          </Row>
          <Table
            columns={columns}
            pagination={{
              position: ['bottomLeft'],
              current: page,
              pageSize: limit,
              total: data?.total ?? 0,
              onChange: (page) => setPage(page),
              onShowSizeChange: (limit) => setLimit(limit)
            }}
            dataSource={data?.data ?? []}
          />
        </Space>
      </Layout.Content>
    </Layout>
  );
}
