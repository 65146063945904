import { Menu, Layout, Dropdown, Avatar, Typography, Row } from 'antd';
import { PRIVATE_ROUTES } from 'routes';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useEffect, useMemo, useState } from 'react';
import { getMe } from 'services';
import { ADMIN_MENU_ID } from 'constants';
import { UserContext } from 'contexts';
import { DownOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

export default function PrivateLayout() {
  // eslint-disable-next-line no-unused-vars
  const [x, xx, removeCookies] = useCookies();
  const history = useHistory();
  const [userData, setUserData] = useState();
  const [allowedMenu, setAllowedMenu] = useState([]);
  const value = { userData, setUserData };

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    const result = await getMe();
    const data = result.data?.data;
    if (data) {
      setAllowedMenu(JSON.parse(data.role.available_menu));
      setUserData(data);
    }
  }

  function checkPenggunaMenu() {
    return allowedMenu.includes(ADMIN_MENU_ID.user) || allowedMenu.includes(ADMIN_MENU_ID.role);
  }

  function checkBeritaMenu() {
    return (
      allowedMenu.includes(ADMIN_MENU_ID.nasional) ||
      allowedMenu.includes(ADMIN_MENU_ID.internasional) ||
      allowedMenu.includes(ADMIN_MENU_ID.cek_fakta) ||
      allowedMenu.includes(ADMIN_MENU_ID.pro_kontra) ||
      allowedMenu.includes(ADMIN_MENU_ID.opini)
    );
  }

  function checkDataMenu() {
    return (
      allowedMenu.includes(ADMIN_MENU_ID.kategori_berita) ||
      allowedMenu.includes(ADMIN_MENU_ID.menu_end_user) ||
      allowedMenu.includes(ADMIN_MENU_ID.kotak_surat) ||
      allowedMenu.includes(ADMIN_MENU_ID.pengacara) ||
      allowedMenu.includes(ADMIN_MENU_ID.iklan) ||
      allowedMenu.includes(ADMIN_MENU_ID.gallery)
    );
  }

  const renderKelolaPengguna = useMemo(
    () =>
      checkPenggunaMenu() && (
        <Menu.ItemGroup title="Kelola Pengguna">
          {allowedMenu.includes(ADMIN_MENU_ID.user) && (
            <Menu.Item>
              <Link to="/users">Users</Link>
            </Menu.Item>
          )}
          {allowedMenu.includes(ADMIN_MENU_ID.role) && (
            <Menu.Item>
              <Link to="/roles">Roles</Link>
            </Menu.Item>
          )}
        </Menu.ItemGroup>
      ),
    [allowedMenu]
  );

  const renderKelolaBerita = useMemo(
    () =>
      checkBeritaMenu() && (
        <Menu.ItemGroup title="Kelola Berita">
          {allowedMenu.includes(ADMIN_MENU_ID.nasional) && (
            <Menu.Item>
              <Link to="/nasional">Nasional</Link>
            </Menu.Item>
          )}
          {allowedMenu.includes(ADMIN_MENU_ID.internasional) && (
            <Menu.Item>
              <Link to="/internasional">Interasional</Link>
            </Menu.Item>
          )}
          {allowedMenu.includes(ADMIN_MENU_ID.cek_fakta) && (
            <Menu.Item>
              <Link to="/cek-fakta">Cek Fakta</Link>
            </Menu.Item>
          )}
          {allowedMenu.includes(ADMIN_MENU_ID.pro_kontra) && (
            <Menu.Item>
              <Link to="/pro-kontra">Pro Kontra</Link>
            </Menu.Item>
          )}
          {allowedMenu.includes(ADMIN_MENU_ID.opini) && (
            <Menu.Item>
              <Link to="/opini">Opini</Link>
            </Menu.Item>
          )}
        </Menu.ItemGroup>
      ),
    [allowedMenu]
  );

  const renderKelolaData = useMemo(
    () =>
      checkDataMenu() && (
        <Menu.ItemGroup title="Kelola Data">
          {allowedMenu.includes(ADMIN_MENU_ID.kategori_berita) && (
            <Menu.Item>
              <Link to="/categories">Kategori Berita</Link>
            </Menu.Item>
          )}
          {allowedMenu.includes(ADMIN_MENU_ID.menu_end_user) && (
            <Menu.Item>
              <Link to="/menus">Menu End-User</Link>
            </Menu.Item>
          )}
          {allowedMenu.includes(ADMIN_MENU_ID.kotak_surat) && (
            <Menu.Item>
              <Link to="/kotak-surat">Kotak Surat</Link>
            </Menu.Item>
          )}
          {allowedMenu.includes(ADMIN_MENU_ID.pengacara) && (
            <Menu.Item>
              <Link to="/konsultasi-hukum">Konsultasi Hukum</Link>
            </Menu.Item>
          )}
          {allowedMenu.includes(ADMIN_MENU_ID.iklan) && (
            <Menu.Item>
              <Link to="/iklan">Iklan</Link>
            </Menu.Item>
          )}
          {allowedMenu.includes(ADMIN_MENU_ID.gallery) && (
            <Menu.Item>
              <Link to="/gallery">Galeri</Link>
            </Menu.Item>
          )}
          {allowedMenu.includes(ADMIN_MENU_ID.structure) && (
            <Menu.Item>
              <Link to="/structure">Struktur Organisasi</Link>
            </Menu.Item>
          )}
        </Menu.ItemGroup>
      ),
    [allowedMenu]
  );

  return (
    <UserContext.Provider value={value}>
      <Layout>
        <Layout.Header
          style={{
            backgroundColor: 'white',
            justifyContent: 'space-between',
            alignItems: 'center',
            display: 'flex',
            padding: '0 2rem'
          }}>
          <img width={161} style={{ objectFit: 'contain' }} src={require('assets/img/logo.png')} />
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="0" onClick={() => history.push('/change-password')}>
                  <Typography.Link strong>Ubah Password</Typography.Link>
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item
                  key="1"
                  onClick={() => {
                    removeCookies('user', { path: '/' });
                    window.location.reload();
                  }}>
                  <Typography.Link strong>Logout</Typography.Link>
                </Menu.Item>
              </Menu>
            }
            trigger={['click']}
            className="hoverable">
            <Row>
              <Avatar size="large" icon={<UserOutlined />} src={userData?.profile_picture} />
              <Typography.Title level={5} style={{ margin: '0 .5rem', lineHeight: '2.25rem' }}>
                {userData?.name ?? 'User'}
              </Typography.Title>
              <DownOutlined style={{ fontSize: '1rem', lineHeight: '2.625rem' }} />
            </Row>
          </Dropdown>
        </Layout.Header>
        <Layout>
          <Layout.Sider
            style={{
              overflow: 'auto',
              height: '100vh',
              left: 0,
              top: 0,
              bottom: 0,
              backgroundColor: 'gray-2'
            }}
            theme="light"
            width={212}>
            <Menu style={{ height: '100%', backgroundColor: 'whitesmoke' }} mode="inline">
              <Menu.Item icon={<MailOutlined />}>
                <Link to="/">Dashboard</Link>
              </Menu.Item>
              {renderKelolaPengguna}
              {renderKelolaBerita}
              {renderKelolaData}
            </Menu>
          </Layout.Sider>
          <Switch>
            {userData
              ? PRIVATE_ROUTES.filter((value) =>
                  value.id ? allowedMenu.includes(value.id) : true
                ).map((routeProps, index) => <Route key={index} exact {...routeProps} />)
              : PRIVATE_ROUTES.map((routeProps, index) => (
                  <Route key={index} exact {...routeProps} />
                ))}
            <Route path="*" render={() => <Redirect to="/" />} />
          </Switch>
        </Layout>
      </Layout>
    </UserContext.Provider>
  );
}
