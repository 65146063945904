// import { DEFAULT_CATEGORIES_ID } from 'constants';
import { requestAPI } from './Request';

export function getNews({ page = 1, limit = 10, title, start_date, end_date, category_id }) {
  return requestAPI.get('/news', {
    params: {
      page,
      limit,
      title,
      start_date,
      end_date,
      category_id
    }
  });
}

export function getNewsDetail(id) {
  return requestAPI.get(`/news/${id}`);
}

export function addNews(data) {
  return requestAPI.post('/news', data);
}

export function updateNews(data) {
  return requestAPI.patch(`/news/${data.id}`, data);
}

export function toggleNewsComment(id) {
  return requestAPI.put(`/news/${id}/toggle-comment`);
}

export function deleteNews(id) {
  return requestAPI.delete(`/news/${id}`);
}

export function verifySurat(id) {
  return requestAPI.get(`/news/verify-surat/${id}`);
}

export function approveOpini(id) {
  return requestAPI.get(`/news/approve/${id}`);
}
