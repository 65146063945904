import axios from 'axios';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();
const user = cookies.get('user');

const requestAPI = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Access-Control-Allow-Origin': '*',
    Authorization: `Bearer ${user?.token}`
  }
});

requestAPI.interceptors.response.use(undefined, (e) => {
  if (
    e?.response?.data?.error == 'token is invalid' ||
    e?.response?.data?.error == 'token is expired'
  ) {
    cookies.remove('user', { path: '/' });
    window.location.reload();
  }

  return e;
});

export { requestAPI };
