import { useState } from 'react';
import { Breadcrumb, Button, Col, Form, Input, Layout, Row, Modal, Checkbox } from 'antd';
import './styles.scss';

import { useHistory, useParams } from 'react-router-dom';

import { Header, Required } from 'component';
import { addStructure, getStructure, updateStructure } from 'services/ServiceStructure';
import { useEffect } from 'react';

export default function AddStructure() {
  const history = useHistory();
  const { id } = useParams();
  const [structureForm] = Form.useForm();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, []);

  async function handleSubmit(values) {
    setLoading(true);

    const result = id
      ? await updateStructure({
          id,
          ...values
        })
      : await addStructure(values);

    if (result.status >= 200 && result.status < 300)
      Modal.success({
        title: id ? 'Data berhasil diubah' : 'Berhasil menambahkan Struktur Organisasi',
        content: id
          ? 'Data Struktur telah berhasil diubah'
          : `Struktur Organisasi telah berhasil ditambahkan`,
        maskClosable: true,
        afterClose: () => history.push('/structure')
      });
    else
      Modal.error({
        title: id ? 'Data gagal diubah' : 'Struktur Organisasi gagal ditambahkan',
        content: 'Mohon periksa kembali data yang kamu input pada form',
        maskClosable: true
      });

    setLoading(false);
  }

  async function fetchData() {
    const result = await getStructure(id);

    const data = result.data?.data;

    if (data) {
      console.log(data);
      structureForm.setFieldsValue(data);
    }
  }

  return (
    <Layout className="container">
      <Header label="Tambah Struktur Organisasi" allowBack />
      <Breadcrumb className="breadcrumb-container">
        <Breadcrumb.Item>Kelola Struktur Organisasi</Breadcrumb.Item>
        <Breadcrumb.Item>Tambah Struktur Organisasi</Breadcrumb.Item>
      </Breadcrumb>
      <Layout.Content style={{ margin: '20px' }}>
        <Form
          className="form-container"
          layout="vertical"
          onFinish={handleSubmit}
          size="large"
          form={structureForm}>
          <Row gutter={24}>
            <Col lg={24} style={{ marginBottom: '1rem' }}>
              <Form.Item
                name="position"
                label={
                  <>
                    Posisi / Jabatan
                    <Required />
                  </>
                }>
                <Input placeholder="Masukkan posisi / jabatan.." />
              </Form.Item>
            </Col>
            <Col lg={24} style={{ marginBottom: '1rem' }}>
              <Form.Item
                name="name"
                label={
                  <>
                    Nama
                    <Required />
                  </>
                }>
                <Input placeholder="Masukkan nama.." />
              </Form.Item>
            </Col>
            <Col lg={24} style={{ marginBottom: '1rem' }}>
              <Form.Item name={'is_main_team'} required valuePropName="checked" initialValue={0}>
                <Checkbox>Team Personalia Redaksi</Checkbox>
              </Form.Item>
            </Col>

            <Col lg={24}>
              <Form.Item>
                <Button type="primary" size="large" htmlType="submit" loading={loading} block>
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Layout.Content>
    </Layout>
  );
}
