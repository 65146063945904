import { requestAPI } from './Request';

export function getIklan({ page = 1, limit = 10 }) {
  return requestAPI.get('/billboards', {
    params: {
      page,
      limit
    }
  });
}

export function getIklanDetail(id) {
  return requestAPI.get(`/billboards/${id}`);
}

export function addIklan(data) {
  return requestAPI.post('/billboards', {
    ...data
  });
}

export function updateIklan(data) {
  return requestAPI.patch(`/billboards/${data.id}`, {
    ...data
  });
}

export function verifyIklan(id) {
  return requestAPI.post(`/billboards/verified/${id}`);
}

export function unverifyIklan(id) {
  return requestAPI.post(`/billboards/unverified/${id}`);
}

export function deleteIklan(id) {
  return requestAPI.delete(`/billboards/${id}`);
}

export function getAdsPrices() {
  return requestAPI.get('/billboards/prices');
}

export function updateAdsPrices(id, price) {
  return requestAPI.patch(`/billboards/prices/${id}`, {
    price: price
  });
}
