import { useEffect, useState } from 'react';
import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Image,
  Input,
  Layout,
  Row,
  Typography,
  Upload,
  Modal,
  Select,
  DatePicker
} from 'antd';
import './styles.scss';
import { PlusOutlined, InboxOutlined } from '@ant-design/icons/lib/icons';
import { useParams, useHistory } from 'react-router-dom';

import { addIklan, getAdsPrices, getIklanDetail, updateIklan, uploadMediaBerita } from 'services';
import { Header, Required } from 'component';
import { formatUploadState } from 'utils';
import moment from 'moment';

const ADS_SIZE = {
  Small: {
    width: 350,
    height: 280
  },
  Medium: {
    width: 350,
    height: 600
  },
  Large: {
    width: 1080,
    height: 250
  }
};

export default function AddIklan() {
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [adsSize, setAdsSize] = useState([]);

  const [image, setImage] = useState({ url: '', name: '' });
  const [title, setTitle] = useState('');
  const [size, setSize] = useState();
  const [endDate, setEndDate] = useState();
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const [allowedType, setAllowedType] = useState(id ? true : false);
  const [uploadState, setUploadState] = useState('EMPTY');

  useEffect(() => {
    fetchAdsSize();
    if (id) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    const currentSize = adsSize?.filter((sizeData) => sizeData?.id === size)?.[0]?.size || 'Large';
    setImageSize({
      width: ADS_SIZE[currentSize].width,
      height: ADS_SIZE[currentSize].height
    });
  }, [adsSize, size]);

  function validate() {
    if (!title) {
      Modal.error({
        title: 'Error',
        content: 'Judul harus diisi',
        maskClosable: true
      });
    } else if (!endDate) {
      Modal.error({
        title: 'Error',
        content: 'Tanggal berlaku harus diisi',
        maskClosable: true
      });
    } else if (!size) {
      Modal.error({
        title: 'Error',
        content: 'Ukuran harus diisi',
        maskClosable: true
      });
    } else if (!image.url) {
      Modal.error({
        title: 'Error',
        content: 'Gambar harus diisi',
        maskClosable: true
      });
    } else if (!allowedType) {
      Modal.error({
        title: 'Error',
        content: 'Tipe file gambar tidak diperbolehkan',
        maskClosable: true
      });
    } else {
      return true;
    }
  }

  function handleBeforeUpload(file) {
    if (file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/png') {
      if (file.size / 1024 / 1024 <= 4) {
        setAllowedType(true);
        return false;
      }
      Modal.error({
        title: 'Error',
        content: `Ukuran File lebih besar dari 4 MB`,
        maskClosable: true
      });

      return true;
    } else {
      Modal.error({
        title: 'Error',
        content: `${file.name} tidak bertipe jpg or jpeg `,
        maskClosable: true
      });

      setAllowedType(false);
      return true;
    }
  }

  async function fetchAdsSize() {
    try {
      const { data } = await getAdsPrices();
      const mutatedAdsSizeData = data?.data?.map((sizeData) => {
        return {
          id: sizeData?.id,
          size: sizeData?.size
        };
      });
      setAdsSize(mutatedAdsSizeData);
    } catch (error) {
      Modal.error({ title: 'Gagal menampilkan harga iklan', content: error });
    }
  }

  async function fetchData() {
    const result = await getIklanDetail(id);

    const data = result?.data?.data;

    if (data) {
      setTitle(data?.name);
      setImage({ ...image, url: data?.path });
      setSize(data?.size_id);
      setEndDate(data?.end_date);
      setUploadState('OCCUPIED');
    }
  }

  async function handleSubmit() {
    setLoading(true);
    if (validate()) {
      try {
        if (id) {
          const result = await updateIklan({
            id: id,
            name: title,
            size_id: size,
            path: image?.url,
            end_date: endDate
          });
          Modal.success({
            title: 'Sukses',
            content: 'Berhasil mengubah iklan',
            maskClosable: true,
            afterClose: () => history.push(`/iklan/${result?.data?.data?.id}`)
          });
        } else {
          const result = await addIklan({
            name: title,
            path: image.url,
            size_id: size,
            end_date: endDate
          });
          Modal.success({
            title: 'Sukses',
            content: 'Berhasil menambahkan iklan',
            maskClosable: true,
            afterClose: () => history.push(`/iklan/${result?.data?.data?.id}`)
          });
        }
      } catch (error) {
        Modal.error({
          title: 'Error',
          content: id ? 'Gagal mengubah iklan' : 'Gagal menambahkan iklan',
          maskClosable: true
        });
      }
    }
    setLoading(false);
  }

  return (
    <Layout className="container">
      <Header label={id ? 'Ubah Iklan' : 'Tambah Iklan'} allowBack />
      <Breadcrumb className="breadcrumb-container">
        <Breadcrumb.Item>Kelola Kotak Iklan</Breadcrumb.Item>
        <Breadcrumb.Item>{id ? 'Ubah Iklan' : 'Tambah Iklan'}</Breadcrumb.Item>
      </Breadcrumb>
      <Layout.Content style={{ margin: '20px' }}>
        <Form className="form-container" layout="vertical" onFinish={handleSubmit} size="large">
          <Row gutter={24}>
            <Col lg={12}>
              <Form.Item
                label={
                  <>
                    Nama Iklan
                    <Required />
                  </>
                }>
                <Input
                  placeholder="Masukkan judul iklan..."
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item
                label={
                  <>
                    Ukuran
                    <Required />
                  </>
                }>
                <Select
                  placeholder="Pilih Ukuran..."
                  onChange={(e) => {
                    setSize(e);
                  }}
                  value={size}
                  showSearch>
                  {adsSize.map(({ id, size }) => (
                    <Select.Option key={id} value={id}>
                      {size}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item
                label={
                  <>
                    Tanggal Berlaku
                    <Required />
                  </>
                }>
                <DatePicker
                  placeholder="Pilih tanggal"
                  style={{ width: '100%' }}
                  value={endDate && moment(endDate)}
                  onChange={(date, dateString) => setEndDate(dateString)}
                />
              </Form.Item>
            </Col>
            <Col lg={24}>
              <Form.Item
                label={
                  <Typography.Text>
                    Gambar Iklan
                    <Required />
                  </Typography.Text>
                }>
                <Row style={{ alignItems: 'center' }} gutter={[8, 8]}>
                  <Col span={24}>
                    <Typography.Text type="secondary">
                      Dimensi yang disarankan untuk gambar iklan sebagai berikut:
                      <br />
                      Large: 250 x 1080 atau kelipatannya
                      <br />
                      Medium: 350 x 600 atau kelipatannya
                      <br />
                      Small: 350 x 280 atau kelipatannya
                    </Typography.Text>
                  </Col>
                  <Col span={24}></Col>
                  <Col span={12}>
                    <Upload.Dragger
                      listType="picture"
                      accept="jpg,jpeg,png"
                      maxCount={1}
                      beforeUpload={handleBeforeUpload}
                      onChange={(e) => {
                        setUploadState('LOADING');
                        if (e.file.status === 'removed') {
                          setUploadState('EMPTY');
                        } else {
                          uploadMediaBerita(e.file)
                            .then((res) => {
                              if (res.data?.data) {
                                setImage({
                                  name: e.file?.name,
                                  url: res.data.data?.path
                                });
                              }
                            })
                            .finally(() => {
                              setUploadState('OCCUPIED');
                            });
                        }
                      }}
                      onRemove={() => {
                        setImage({ name: '', url: '' });
                      }}
                      style={{ width: '550px', maxWidth: '550px' }}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">Upload gambar iklan (Max 4MB)</p>
                      <p className="ant-upload-hint">Format gambar .png, .jpg atau .jpeg</p>
                    </Upload.Dragger>
                    <span style={{ padding: '0 18px', color: '#BDBDBD' }}>
                      {formatUploadState(uploadState)}
                    </span>
                  </Col>
                </Row>
              </Form.Item>
              {image.url && (
                <div style={{ marginBottom: '16px' }}>
                  <Image
                    style={{
                      objectFit: 'contain',
                      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px'
                    }}
                    width={imageSize.width}
                    height={imageSize.height}
                    src={image.url}
                  />
                </div>
              )}
            </Col>
            <Col lg={12}>
              <Form.Item>
                <Button
                  type="primary"
                  icon={!id && <PlusOutlined />}
                  size="large"
                  htmlType="submit"
                  loading={loading}>
                  {id ? 'Ubah Iklan' : 'Tambah Iklan'}
                </Button>
                {id && (
                  <Button
                    type="text"
                    size="large"
                    onClick={() => history.goBack()}
                    style={{ marginLeft: '1rem', color: '#3F8FE7' }}>
                    BATAL
                  </Button>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Layout.Content>
    </Layout>
  );
}
