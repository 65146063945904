import { useEffect, useState } from 'react';
import { Button, Col, Image, Layout, Modal, Row, Space, Typography, Tag } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import './styles.scss';

import { Header } from 'component';
import { deleteIklan, getIklanDetail, unverifyIklan, verifyIklan } from 'services';
import {
  CloseOutlined,
  EditOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons/lib/icons';
import { IMAGE_PLACEHOLDER } from 'constants';
import { DateFormatter } from 'utils';

export default function DetailIklan() {
  const { id } = useParams();
  const history = useHistory();
  const [data, setData] = useState();
  const [preview, setPreview] = useState('');

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    const result = await getIklanDetail(id);
    console.log(result?.data?.data);
    setData(result?.data?.data);
  }

  function openDeleteModal() {
    Modal.confirm({
      title: 'Hapus Iklan ini?',
      content: 'Apakah kamu yakin ingin menghapus iklan ini?',
      icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
      onOk() {
        deleteIklan(id).then(() => {
          history.push('/nasional');
        });
      }
    });
  }

  function openModalVerify() {
    Modal.confirm({
      title: 'Verifikasi iklan ini?',
      content: 'Apakah kamu yakin ingin menverifikasi iklan ini?',
      icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
      onOk() {
        verifyIklan(id).then(() => {
          fetchData();
        });
      }
    });
  }

  function openModalUnVerified() {
    Modal.confirm({
      title: 'Unverifikasi iklan ini?',
      content: 'Apakah kamu yakin ingin menunverifikasi iklan ini?',
      icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
      onOk() {
        unverifyIklan(id).then(() => {
          fetchData();
        });
      }
    });
  }

  return (
    <Layout className="container">
      <Header label="Kelola Iklan" allowBack />
      <Row>
        <Col lg={24}>
          <Layout.Content className="content-container">
            <Row gutter={[8, 8]}>
              <Col span={12}>
                <Typography.Text type="secondary">Diajukan oleh</Typography.Text>
                <Typography.Title
                  style={{ color: '#1890FF' }}
                  onClick={() => history.push(`/users/${data?.buyer?.id}`)}
                  strong
                  className="editor-detail"
                  level={5}>
                  {data?.buyer?.email ?? '-'}
                </Typography.Title>
              </Col>
              <Col span={12}>
                <Typography.Text type="secondary">Tanggal Pembuatan</Typography.Text>
                <Typography.Title className="editor-detail" level={5}>
                  {DateFormatter(data?.created_at)}
                </Typography.Title>
              </Col>
              <Col span={12}>
                <Typography.Text type="secondary">Terakhir diupdate oleh</Typography.Text>
                <Typography.Title
                  style={{ color: '#1890FF' }}
                  onClick={() => history.push(`/users/${data?.verifier?.id}`)}
                  strong
                  className="editor-detail"
                  level={5}>
                  {data?.verifier?.email ?? '-'}
                </Typography.Title>
              </Col>
              <Col span={12}>
                <Typography.Text type="secondary">Status Iklan</Typography.Text>
                <div>
                  <Tag color={data?.is_verified ? 'green' : 'magenta'}>
                    {data?.is_verified ? 'Terverifikasi' : 'Belum diverifikasi'}
                  </Tag>
                </div>
              </Col>
              <Col span={12}>
                <Typography.Text type="secondary">Periode Aktif</Typography.Text>
                <Typography.Title className="editor-detail" level={5}>
                  {DateFormatter(data?.end_date)}
                </Typography.Title>
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col span={12}>
                <Typography.Text type="secondary">Deskripsi Iklan</Typography.Text>
                <Typography.Title strong className="editor-detail" level={5}>
                  {data?.name ?? '-'}
                </Typography.Title>
              </Col>
              <Col span={12}>
                <Typography.Text type="secondary">Ukuran Iklan</Typography.Text>
                <Typography.Title
                  style={{ color: '#1890FF' }}
                  strong
                  className="editor-detail"
                  level={5}>
                  {data?.size?.size ?? '-'}
                </Typography.Title>
              </Col>
              <Col span={12}>
                <Typography.Text type="secondary">Bukti Transfer</Typography.Text>
                <div>
                  {data?.payment_path ? (
                    <Button
                      type="link"
                      style={{ paddingInline: 0 }}
                      onClick={() => {
                        setPreview(data?.payment_path);
                      }}>
                      Click untuk menampilkan Bukti Pembayaran
                    </Button>
                  ) : (
                    '-'
                  )}
                </div>
              </Col>
              <Col span={24}>
                <Typography.Text type="secondary">Gambar</Typography.Text>
                <div style={{ marginBottom: '16px' }}>
                  <Button
                    type="link"
                    style={{ paddingInline: 0 }}
                    onClick={() => {
                      setPreview(data?.path);
                    }}>
                    Click untuk menampilkan Gambar Iklan
                  </Button>
                </div>
              </Col>
            </Row>
            <Space direction="vertical" size="large">
              <Space size="large">
                {!data?.is_verified ? (
                  <Button
                    type="primary"
                    style={{ backgroundColor: '#66AD20', border: 0 }}
                    size="large"
                    onClick={openModalVerify}>
                    Verifikasi
                  </Button>
                ) : (
                  <Button type="danger" size="large" onClick={openModalUnVerified}>
                    Unverifikasi
                  </Button>
                )}
                <Button
                  type="primary"
                  icon={<EditOutlined />}
                  size="large"
                  onClick={() => history.push(`/iklan/update/${id}`)}>
                  Ubah Iklan
                </Button>
                <Button
                  type="primary"
                  danger
                  icon={<CloseOutlined />}
                  size="large"
                  onClick={openDeleteModal}>
                  Hapus Iklan
                </Button>
              </Space>
            </Space>
          </Layout.Content>
        </Col>
      </Row>
      <Image
        width={200}
        style={{ display: 'none' }}
        src={preview}
        preview={{
          visible: preview !== '',
          src: preview,
          onVisibleChange: () => setPreview('')
        }}
        fallback={IMAGE_PLACEHOLDER}
      />
    </Layout>
  );
}
