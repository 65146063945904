import { ADMIN_MENU_ID } from 'constants';
import { requestAPI } from './Request';

export function getKotakSurat({ page = 1, limit = 10, title, start_date, end_date }) {
  console.log(ADMIN_MENU_ID.kotak_surat);
  return requestAPI.get('/news', {
    params: {
      page,
      limit,
      title,
      start_date,
      end_date,
      category_id: ADMIN_MENU_ID.kotak_surat
    }
  });
}

export function getKotakSuratDetail(id) {
  return requestAPI.get(`/news/${id}`);
}

export function addKotakSurat(data) {
  return requestAPI.post('/news', {
    ...data,
    location: 'DKI Jakarta',
    category_id: ADMIN_MENU_ID.kotak_surat
  });
}

export function updateKotakSurat(data) {
  return requestAPI.patch(`/news/${data.id}`, {
    ...data,
    category_id: ADMIN_MENU_ID.kotak_surat
  });
}

export function toggleKotakSuratComment(id) {
  return requestAPI.put(`/news/${id}/toggle-comment`);
}

export function deleteKotakSurat(id) {
  return requestAPI.delete(`/news/${id}`);
}
