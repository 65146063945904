import { SearchOutlined } from '@ant-design/icons/lib/icons';
import { Breadcrumb, Button, Input, Layout, Row, Space, Table, Typography } from 'antd';
import { Header } from 'component';
import { useEffect, useState } from 'react';
import { updateMenu, getMenus } from 'services';
import './styles.scss';

export default function ManageMenuEndUser() {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState('');

  const [data, setData] = useState();

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    const result = await getMenus();

    setData(result.data?.data.filter((item) => !item.is_admin_menu));
  }

  const columns = [
    {
      title: 'Nama Menu',
      dataIndex: 'name',
      key: 'name',
      width: 500
    },
    {
      title: 'Status',
      dataIndex: 'is_active',
      key: 'is_active',
      render: (value) =>
        value ? (
          <Typography.Text type="success" strong>
            Aktif
          </Typography.Text>
        ) : (
          <Typography.Text type="danger" strong>
            Non-aktif
          </Typography.Text>
        )
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'action',
      width: '250px',
      render: (id, row) =>
        row.is_active ? (
          <Button
            type="primary"
            danger
            onClick={() => {
              updateMenu(id).then(fetchData);
            }}>
            Non-aktifkan
          </Button>
        ) : (
          <Button
            type="primary"
            onClick={() => {
              updateMenu(id).then(fetchData);
            }}>
            Aktifkan
          </Button>
        )
    }
  ];

  return (
    <Layout className="container">
      <Header label="Kelola Menu End-user" />
      <Breadcrumb className="breadcrumb-container">
        <Breadcrumb.Item>Kelola Menu End-user</Breadcrumb.Item>
      </Breadcrumb>
      <Layout.Content className="content-container">
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Row>
            <Input
              style={{ width: '25%' }}
              placeholder="Cari data..."
              prefix={<SearchOutlined />}
              onChange={(e) => setFilter(e.target.value)}
            />
          </Row>
          <Table
            columns={columns}
            pagination={{
              position: ['bottomLeft'],
              current: page,
              pageSize: limit,
              total: data?.total ?? 0,
              onChange: (page) => setPage(page),
              onShowSizeChange: (x, size) => setLimit(size)
            }}
            dataSource={data?.filter((value) => (filter ? value.name?.includes(filter) : value))}
          />
        </Space>
      </Layout.Content>
    </Layout>
  );
}
