import { PlusOutlined, SearchOutlined } from '@ant-design/icons/lib/icons';
import { Breadcrumb, Button, Image, Input, Layout, Modal, Row, Space, Table } from 'antd';
import { Header } from 'component';
import { IMAGE_PLACEHOLDER } from 'constants';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { deleteGallery, getGalleries, unverifGallery, verifGallery } from 'services';
import './styles.scss';

export default function ManageGallery() {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState('');
  let debounce = undefined;

  const [preview, setPreview] = useState('');
  const [data, setData] = useState();

  useEffect(() => {
    debounce = setTimeout(() => {
      fetchData();
    }, 300);

    return () => clearTimeout(debounce);
  }, [page, limit, filter]);

  async function fetchData() {
    const result = await getGalleries({ page, limit, name: filter });
    setData(result.data?.data);
  }

  function handleVerifGallery(id) {
    verifGallery(id).then(() => {
      Modal.success({ title: 'Verifikasi Success', content: 'Sukses memverifikasi gambar.' });
      fetchData();
    });
  }

  function handleUnverifGallery(id) {
    unverifGallery(id).then(() => {
      Modal.success({
        title: 'Unverifikasi Success',
        content: 'Sukses membatalkan verifikasi gambar.'
      });
      fetchData();
    });
  }

  function openModal(data) {
    Modal.confirm({
      title: 'Hapus galeri ini?',
      content: `Apakah kamu yakin ingin menghapus galeri ${data.name}?`,
      onOk() {
        deleteGallery(data.id)
          .then(() => {
            Modal.success({
              title: 'Galeri berhasil dihapus',
              content: `Kamu berhasil menghapus galeri ${data.name}`,
              afterClose: () => fetchData()
            });
          })
          .catch(() => {
            Modal.error({
              title: 'Galeri gagal dihapus',
              content: `Galeri tidak bisa dihapus, mohon coba lagi nanti`
            });
          });
      }
    });
  }

  const columns = [
    {
      title: 'Nama Galeri',
      dataIndex: 'name',
      key: 'name',
      render: (name, row) => (
        <Button
          type="link"
          style={{ paddingInline: 0 }}
          onClick={() => {
            setPreview(row.path);
          }}>
          {name}
        </Button>
      )
    },
    {
      title: 'Diupload oleh',
      dataIndex: 'user',
      key: 'id',
      render: (user) => user?.name ?? user?.email
    },
    {
      title: 'Diverifikasi oleh',
      dataIndex: 'verifier',
      key: 'id',
      render: (user) => user?.name ?? '-'
    },
    {
      title: 'Action',
      key: 'action',
      width: '250px',
      render: (row) => (
        <Space>
          {!row?.verifier_id ? (
            <Button type="primary" onClick={() => handleVerifGallery(row?.id)}>
              Verifikasi
            </Button>
          ) : (
            <Button type="primary" danger onClick={() => handleUnverifGallery(row?.id)}>
              Unverifikasi
            </Button>
          )}
          <Button type="ghost" danger onClick={() => openModal(row)}>
            Hapus
          </Button>
        </Space>
      )
    }
  ];

  return (
    <Layout className="container">
      <Header label="Kelola Galeri" />
      <Breadcrumb className="breadcrumb-container">
        <Breadcrumb.Item>Kelola Galeri</Breadcrumb.Item>
      </Breadcrumb>
      <Layout.Content className="content-container">
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Row>
            <Input
              style={{ width: '25%' }}
              placeholder="Cari galeri..."
              prefix={<SearchOutlined />}
              onChange={(e) => setFilter(e.target.value)}
            />
            <Button
              style={{ marginLeft: 'auto', color: '#1890FF', borderColor: '#1890FF' }}
              icon={<PlusOutlined />}
              onClick={() => history.push('/gallery/add')}>
              Tambah galeri
            </Button>
          </Row>
          <Table
            columns={columns}
            pagination={{
              position: ['bottomLeft'],
              current: page,
              pageSize: limit,
              total: data?.total ?? 0,
              onChange: (page) => setPage(page),
              onShowSizeChange: (x, size) => setLimit(size)
            }}
            dataSource={data?.data ?? []}
          />
        </Space>
      </Layout.Content>
      <Image
        width={200}
        style={{ display: 'none' }}
        src={preview}
        preview={{
          visible: preview !== '',
          src: preview,
          onVisibleChange: () => setPreview('')
        }}
        fallback={IMAGE_PLACEHOLDER}
      />
    </Layout>
  );
}
