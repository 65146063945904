import { useEffect, useState } from 'react';
import {
  Breadcrumb,
  Button,
  Col,
  Checkbox,
  Layout,
  Modal,
  Row,
  Space,
  Typography,
  Tag,
  Avatar,
  Carousel,
  Image
} from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import './styles.scss';

import { Header } from 'component';
import { deleteKotakSurat, getKotakSuratDetail, toggleKotakSuratComment } from 'services';
import {
  CloseOutlined,
  DislikeOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  LikeOutlined,
  UserOutlined
} from '@ant-design/icons/lib/icons';
import moment from 'moment';
import { STATUS_NEWS } from 'constants/status';
import { verifySurat } from 'services/ServiceNews';

export default function DetailKotakSurat() {
  const { id } = useParams();
  const history = useHistory();
  const [data, setData] = useState();

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    const result = await getKotakSuratDetail(id);
    let currentBannerPath = result?.data?.data?.banner_path || '';
    let formattedBanner = [];
    if (String(currentBannerPath)?.indexOf('[') !== -1) {
      formattedBanner = JSON.parse(currentBannerPath);
    } else {
      formattedBanner = [currentBannerPath];
    }
    const newsData = {
      ...result?.data?.data,
      banners: formattedBanner
    };
    setData(newsData);
  }

  function handleToggleComment() {
    toggleKotakSuratComment(id).then(() => fetchData());
  }

  function openModal() {
    Modal.confirm({
      title: 'Hapus surat ini?',
      content: 'Apakah kamu yakin ingin menghapus surat ini?',
      icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
      onOk() {
        deleteKotakSurat(id).then(() => {
          history.push('/kotak-surat');
        });
      }
    });
  }

  function openModalVerify() {
    Modal.confirm({
      title: 'Verifikasi surat ini?',
      content: 'Apakah kamu yakin ingin memverifikasi surat ini?',
      icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
      onOk() {
        verifySurat(id).then(() => {
          fetchData();
        });
      }
    });
  }

  return (
    <Layout className="container">
      <Header label={data?.title ?? '-'} allowBack />
      <Breadcrumb className="breadcrumb-container">
        <Breadcrumb.Item>Kelola Surat</Breadcrumb.Item>
        <Breadcrumb.Item>{data?.title ?? '-'}</Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col lg={14}>
          <Layout.Content className="content-container">
            <Space align="center" direction="horizontal" size={'small'} wrap>
              <Tag
                color={data?.category?.color}
                style={{ borderRadius: 12, border: 0, padding: '4px 12px', fontWeight: 600 }}>
                {data?.category?.name ?? '-'}
              </Tag>
              {data?.sub_categories?.map((subCtg) => (
                <Tag
                  key={subCtg?.id}
                  color={subCtg?.color}
                  style={{ borderRadius: 12, border: 0, padding: '4px 12px', fontWeight: 600 }}>
                  {subCtg?.name ?? '-'}
                </Tag>
              ))}
            </Space>
            <Typography.Title level={3} style={{ margin: '1rem 0' }}>
              {data?.title ?? '-'}
            </Typography.Title>
            <Row style={{ margin: '1rem 0' }}>
              <Space>
                <Avatar icon={<UserOutlined />} src={data?.created_by?.profile_picture} size={35} />
                <Col>
                  <div>
                    Penulis{' '}
                    <Typography.Text style={{ color: '#1890FF' }} strong>
                      {data?.created_by?.name}
                    </Typography.Text>
                  </div>
                  <div>{moment(data?.created_at).format('dddd, DD MMMM YYYY - HH.mm')}</div>
                </Col>
              </Space>
              <Col
                flex="auto"
                style={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
                <span>
                  <LikeOutlined /> {data?.total_like ?? 0}
                </span>
                <span style={{ margin: '0 2rem' }}>
                  <DislikeOutlined /> {data?.total_like ?? 0}
                </span>
                <span>
                  <img
                    width={14}
                    height={14}
                    style={{ marginRight: '.25rem' }}
                    src={require('assets/img/comment-icon.png')}
                  />
                  {data?.comments?.length ?? 0}
                </span>
              </Col>
            </Row>
            {Boolean(data?.banners?.length) && (
              <Carousel autoplay dotPosition="bottom" style={{ width: 653 }}>
                {data?.banners?.map((img, index) => (
                  <div key={index}>
                    <Image
                      preview={false}
                      style={{ objectFit: 'cover' }}
                      width={653}
                      height={416}
                      src={img}
                    />
                  </div>
                ))}
              </Carousel>
            )}
            {/* <img
              width="100%"
              style={{ margin: '2rem 0', borderRadius: 10 }}
              src={data?.banner_path}
            /> */}
            <div
              className="news-content"
              dangerouslySetInnerHTML={{
                __html: data?.content ?? ''
              }}
            />
          </Layout.Content>
        </Col>
        <Col lg={10}>
          <Layout.Content className="content-container">
            <Row>
              <Col span={24}>
                <Typography.Text type="secondary">Dibuat oleh</Typography.Text>
                <Typography.Title
                  style={{ color: '#1890FF' }}
                  onClick={() => history.push(`/users/${data?.created_by?.id}`)}
                  strong
                  className="editor-detail"
                  level={5}>
                  {data?.created_by?.email}
                </Typography.Title>
              </Col>
              <Col span={24}>
                <Typography.Text type="secondary">Diverifikasi oleh</Typography.Text>
                <Typography.Title
                  style={{ color: '#1890FF' }}
                  onClick={() => history.push(`/users/${data?.created_by?.id}`)}
                  strong
                  className="editor-detail"
                  level={5}>
                  {!data?.approved_by ? '-' : data?.approved_by?.name}
                </Typography.Title>
              </Col>
              <Col span={24}>
                <Typography.Text type="secondary">Status</Typography.Text>
                <Typography.Title strong className="editor-detail" level={5}>
                  <div style={{ color: STATUS_NEWS?.[data?.status]?.color }}>
                    {STATUS_NEWS?.[data?.status]?.label}
                  </div>
                </Typography.Title>
              </Col>
            </Row>
            <Space direction="vertical" size="large">
              <Space size="large">
                {data?.status === 0 && (
                  <Button
                    type="primary"
                    style={{ backgroundColor: '#66AD20', border: 0 }}
                    size="large"
                    onClick={() => openModalVerify()}>
                    Verifikasi
                  </Button>
                )}
                {data?.status === 0 && (
                  <Button
                    type="primary"
                    icon={<EditOutlined />}
                    size="large"
                    onClick={() => history.push(`/kotak-surat/update/${id}`)}>
                    Ubah Surat
                  </Button>
                )}
                {(data?.status === 0 || data?.status === 2) && (
                  <Button
                    type="primary"
                    danger
                    icon={<CloseOutlined />}
                    size="large"
                    onClick={() => openModal()}>
                    Hapus Surat
                  </Button>
                )}
              </Space>
              {data?.status === 3 && (
                <Checkbox checked={data?.comment_toggle ?? false} onClick={handleToggleComment}>
                  Ijinkan komentar
                </Checkbox>
              )}
            </Space>
          </Layout.Content>
        </Col>
      </Row>
    </Layout>
  );
}
