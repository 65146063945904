import Home from 'pages/home';
import Login from 'pages/login';
import {
  AddNasional,
  DetailNasional,
  ManageNasional,
  AddUser,
  DetailUser,
  ManageUser,
  VerifyUser,
  ManageRole,
  AddRole,
  ManageCategory,
  AddCategory,
  ManageInternasional,
  DetailInternasional,
  AddInternasional,
  ManageCekFakta,
  AddCekFakta,
  DetailCekFakta,
  ManageProKontra,
  AddProKontra,
  DetailProKontra,
  ManageMenuEndUser,
  ManageGallery,
  AddGallery,
  ChangePassword,
  ManageOpini,
  AddOpini,
  DetailOpini,
  ManageKotakSurat,
  AddKotakSurat,
  DetailKotakSurat,
  ManageIklan,
  AddIklan,
  DetailIklan,
  AddKonsultasiHukum,
  ManageKonsultasiHukum
} from 'pages';
import { ADMIN_MENU_ID } from 'constants';
import ManageStructure from 'pages/structure/ManageStructure';
import AddStructure from 'pages/structure/AddStructure';

export const PRIVATE_ROUTES = [
  {
    path: '/',
    children: <Home />
  },
  {
    id: ADMIN_MENU_ID.user,
    path: '/users',
    children: <ManageUser />
  },
  {
    id: ADMIN_MENU_ID.user,
    path: '/users/add',
    children: <AddUser />
  },
  {
    id: ADMIN_MENU_ID.user,
    path: '/users/update/:id',
    children: <AddUser />
  },
  {
    id: ADMIN_MENU_ID.user,
    path: '/users/verify/:id',
    children: <VerifyUser />
  },
  {
    id: ADMIN_MENU_ID.user,
    path: '/users/:id',
    children: <DetailUser />
  },
  {
    id: ADMIN_MENU_ID.role,
    path: '/roles',
    children: <ManageRole />
  },
  {
    id: ADMIN_MENU_ID.role,
    path: '/roles/add',
    children: <AddRole />
  },
  {
    id: ADMIN_MENU_ID.role,
    path: '/roles/update/:id',
    children: <AddRole />
  },
  {
    id: ADMIN_MENU_ID.kategori_berita,
    path: '/categories',
    children: <ManageCategory />
  },
  {
    id: ADMIN_MENU_ID.kategori_berita,
    path: '/categories/add',
    children: <AddCategory />
  },
  {
    id: ADMIN_MENU_ID.kategori_berita,
    path: '/categories/update/:id',
    children: <AddCategory />
  },
  {
    id: ADMIN_MENU_ID.nasional,
    path: '/nasional',
    children: <ManageNasional />
  },
  {
    id: ADMIN_MENU_ID.nasional,
    path: '/nasional/update/:id',
    children: <AddNasional />
  },
  {
    id: ADMIN_MENU_ID.nasional,
    path: '/nasional/add',
    children: <AddNasional />
  },
  {
    id: ADMIN_MENU_ID.nasional,
    path: '/nasional/:id',
    children: <DetailNasional />
  },
  {
    id: ADMIN_MENU_ID.internasional,
    path: '/internasional',
    children: <ManageInternasional />
  },
  {
    id: ADMIN_MENU_ID.internasional,
    path: '/internasional/add',
    children: <AddInternasional />
  },
  {
    id: ADMIN_MENU_ID.internasional,
    path: '/internasional/update/:id',
    children: <AddInternasional />
  },
  {
    id: ADMIN_MENU_ID.internasional,
    path: '/internasional/:id',
    children: <DetailInternasional />
  },
  {
    id: ADMIN_MENU_ID.cek_fakta,
    path: '/cek-fakta',
    children: <ManageCekFakta />
  },
  {
    id: ADMIN_MENU_ID.cek_fakta,
    path: '/cek-fakta/add',
    children: <AddCekFakta />
  },
  {
    id: ADMIN_MENU_ID.cek_fakta,
    path: '/cek-fakta/update/:id',
    children: <AddCekFakta />
  },
  {
    id: ADMIN_MENU_ID.cek_fakta,
    path: '/cek-fakta/:id',
    children: <DetailCekFakta />
  },
  {
    id: ADMIN_MENU_ID.pro_kontra,
    path: '/pro-kontra',
    children: <ManageProKontra />
  },
  {
    id: ADMIN_MENU_ID.pro_kontra,
    path: '/pro-kontra/add',
    children: <AddProKontra />
  },
  {
    id: ADMIN_MENU_ID.pro_kontra,
    path: '/pro-kontra/update/:id',
    children: <AddProKontra />
  },
  {
    id: ADMIN_MENU_ID.pro_kontra,
    path: '/pro-kontra/:id',
    children: <DetailProKontra />
  },
  {
    id: ADMIN_MENU_ID.menu_end_user,
    path: '/menus',
    children: <ManageMenuEndUser />
  },
  {
    id: ADMIN_MENU_ID.opini,
    path: '/opini',
    children: <ManageOpini />
  },
  {
    id: ADMIN_MENU_ID.opini,
    path: '/opini/update/:id',
    children: <AddOpini />
  },
  {
    id: ADMIN_MENU_ID.opini,
    path: '/opini/add',
    children: <AddOpini />
  },
  {
    id: ADMIN_MENU_ID.opini,
    path: '/opini/:id',
    children: <DetailOpini />
  },
  {
    id: ADMIN_MENU_ID.kotak_surat,
    path: '/kotak-surat',
    children: <ManageKotakSurat />
  },
  {
    id: ADMIN_MENU_ID.kotak_surat,
    path: '/kotak-surat/update/:id',
    children: <AddKotakSurat />
  },
  {
    id: ADMIN_MENU_ID.kotak_surat,
    path: '/kotak-surat/add',
    children: <AddKotakSurat />
  },
  {
    id: ADMIN_MENU_ID.kotak_surat,
    path: '/kotak-surat/:id',
    children: <DetailKotakSurat />
  },
  {
    id: ADMIN_MENU_ID.pengacara,
    path: '/konsultasi-hukum',
    children: <ManageKonsultasiHukum />
  },
  {
    id: ADMIN_MENU_ID.pengacara,
    path: '/konsultasi-hukum/add',
    children: <AddKonsultasiHukum />
  },
  {
    id: ADMIN_MENU_ID.pengacara,
    path: '/konsultasi-hukum/update/:id',
    children: <AddKonsultasiHukum />
  },
  {
    id: ADMIN_MENU_ID.iklan,
    path: '/iklan',
    children: <ManageIklan />
  },
  {
    id: ADMIN_MENU_ID.iklan,
    path: '/iklan/update/:id',
    children: <AddIklan />
  },
  {
    id: ADMIN_MENU_ID.iklan,
    path: '/iklan/add',
    children: <AddIklan />
  },
  {
    id: ADMIN_MENU_ID.iklan,
    path: '/iklan/:id',
    children: <DetailIklan />
  },
  {
    id: ADMIN_MENU_ID.gallery,
    path: '/gallery',
    children: <ManageGallery />
  },
  {
    id: ADMIN_MENU_ID.gallery,
    path: '/gallery/add',
    children: <AddGallery />
  },
  {
    path: '/change-password',
    children: <ChangePassword />
  },
  {
    id: ADMIN_MENU_ID.structure,
    path: '/structure',
    children: <ManageStructure></ManageStructure>
  },
  {
    id: ADMIN_MENU_ID.structure,
    path: '/structure/add',
    children: <AddStructure></AddStructure>
  },
  {
    id: ADMIN_MENU_ID.structure,
    path: '/structure/:id',
    children: <AddStructure></AddStructure>
  }
];

export const PUBLIC_ROUTES = {
  path: '/',
  children: <Login />
};
