import { requestAPI } from './Request';

export async function login(data) {
  return requestAPI.post('/auth/login-admin', data);
}

export async function getMe() {
  return requestAPI.get('/auth/me');
}

export function resetPassword(email) {
  return requestAPI.put('/auth/reset-password', { email });
}
