export const ADMIN_MENU = [
  { id: '8787965e-f456-4a7b-bad6-13365d89d43f', name: 'User' },
  { id: '6e342dd1-60ae-4b3d-b36b-0eb2b5d3e370', name: 'Role' },
  { id: '95950b46-8da6-4e9e-bb17-1c3a897bfe70', name: 'Nasional' },
  { id: 'af6d0284-000c-4d79-9513-4413c8add614', name: 'Internasional' },
  { id: 'aae227ba-707e-479e-a75e-54ad620f2e85', name: 'Cek Fakta' },
  { id: '79a940a1-2907-4d30-8162-9916ef0b0e31', name: 'Pro Kontra' },
  { id: 'aee443d4-372c-48d0-b19c-0edc5e8c6b9a', name: 'Opini' },
  { id: 'd78068e0-a971-453e-9b81-82a502af1835', name: 'Iklan' },
  { id: 'aab7f35f-8d9f-4722-95ba-f2613bae349f', name: 'Gallery' },
  { id: '153631a1-4c99-40bf-90be-ea39f0a5d67d', name: 'Kategori Berita' },
  { id: 'f2d1c852-a2d3-4496-9dfb-858dba06a6b8', name: 'Menu End User' },
  { id: 'acb2c361-1ec6-442a-8b4a-fddaf559c211', name: 'Kotak Surat' },
  { id: '73f792d7-7c08-4f60-915a-41658cb1e4ec', name: 'Pengacara' },
  { id: '35fd188d-3f5e-11ee-b012-1255723ddb93', name: 'Struktur Organisasi' }
];

export const ADMIN_MENU_ID = {
  user: '8787965e-f456-4a7b-bad6-13365d89d43f',
  role: '6e342dd1-60ae-4b3d-b36b-0eb2b5d3e370',
  nasional: '95950b46-8da6-4e9e-bb17-1c3a897bfe70',
  internasional: 'af6d0284-000c-4d79-9513-4413c8add614',
  cek_fakta: 'aae227ba-707e-479e-a75e-54ad620f2e85',
  pro_kontra: '79a940a1-2907-4d30-8162-9916ef0b0e31',
  opini: 'aee443d4-372c-48d0-b19c-0edc5e8c6b9a',
  iklan: 'd78068e0-a971-453e-9b81-82a502af1835',
  gallery: 'aab7f35f-8d9f-4722-95ba-f2613bae349f',
  kategori_berita: '153631a1-4c99-40bf-90be-ea39f0a5d67d',
  menu_end_user: 'f2d1c852-a2d3-4496-9dfb-858dba06a6b8',
  kotak_surat: 'acb2c361-1ec6-442a-8b4a-fddaf559c211',
  pengacara: '73f792d7-7c08-4f60-915a-41658cb1e4ec',
  structure: '35fd188d-3f5e-11ee-b012-1255723ddb93'
};
