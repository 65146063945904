import { PlusOutlined, CheckCircleTwoTone } from '@ant-design/icons/lib/icons';
import {
  Button,
  Layout,
  Row,
  Space,
  Table,
  Typography,
  Col,
  InputNumber,
  Divider,
  Modal
} from 'antd';
import { Header } from 'component';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getAdsPrices, getIklan, updateAdsPrices } from 'services';
import { DateFormatter } from 'utils';
import './styles.scss';

export default function ManageIklan() {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  // const [filter, setFilter] = useState({
  //   start_date: undefined,
  //   end_date: undefined
  // });
  const [loading, setLoading] = useState({
    price: false,
    data: false
  });
  const [prices, setPrices] = useState({
    small: {
      id: '',
      value: 0
    },
    medium: {
      id: '',
      value: 0
    },
    large: {
      id: '',
      value: 0
    }
  });
  let debounce = undefined;

  const [data, setData] = useState();

  useEffect(() => {
    fetchAdsPrices();
  }, []);

  useEffect(() => {
    debounce = setTimeout(() => {
      fetchData();
    }, 300);

    return () => clearTimeout(debounce);
  }, [page, limit]);

  async function fetchAdsPrices() {
    try {
      const { data } = await getAdsPrices();
      setPrices({
        small: {
          id: data?.data?.[0]?.id,
          value: Number(data?.data?.[0]?.price)
        },
        medium: {
          id: data?.data?.[1]?.id,
          value: Number(data?.data?.[1]?.price)
        },
        large: {
          id: data?.data?.[2]?.id,
          value: Number(data?.data?.[2]?.price)
        }
      });
    } catch (error) {
      Modal.error({ title: 'Gagal menampilkan harga iklan', content: error });
    }
  }

  async function fetchData() {
    try {
      setLoading((prev) => ({ ...prev, data: true }));
      const result = await getIklan({ page, limit });
      setData(result.data?.data);
    } catch (error) {
      Modal.error({ title: 'Gagal menampilkan iklan', content: error });
    } finally {
      setLoading((prev) => ({ ...prev, data: false }));
    }
  }

  function handlePriceChange(size, value) {
    setPrices((prev) => ({
      ...prev,
      [size]: {
        ...prev[size],
        value: value
      }
    }));
  }

  async function handleConfirmUpdatePrice() {
    try {
      setLoading((prev) => ({ ...prev, price: true }));
      Promise.all([
        updateAdsPrices(prices.small.id, prices.small.value),
        updateAdsPrices(prices.medium.id, prices.medium.value),
        updateAdsPrices(prices.large.id, prices.large.value)
      ]);
    } catch (error) {
      Modal.error({ title: 'Gagal mengupdate harga iklan', content: error });
    } finally {
      Modal.success({ title: 'Berhasil mengupdate harga iklan' });
      setLoading((prev) => ({ ...prev, price: false }));
    }
  }

  const columns = [
    {
      title: 'Nama Iklan',
      dataIndex: 'name',
      key: 'name',
      width: '45%'
    },
    {
      title: 'Ukuran',
      dataIndex: 'size',
      key: 'id',
      render: (value) => value?.size
    },
    {
      title: 'Terverifikasi',
      dataIndex: 'is_verified',
      key: 'is_verified',
      render: (value) => <>{value ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : '-'}</>
    },
    {
      title: 'Tanggal Berakhir',
      dataIndex: 'end_date',
      key: 'end_date',
      render: (date) => DateFormatter(date)
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'action',
      width: '250px',
      render: (id) => (
        <Button type="primary" onClick={() => history.push(`/iklan/${id}`)}>
          Lihat
        </Button>
      )
    }
  ];

  return (
    <Layout className="container">
      <Header label="Kelola Iklan" />
      <Layout.Content className="ads-container">
        <Space direction="vertical" style={{ width: '100%' }}>
          <Row>
            <Typography.Title level={4}>Harga Iklan</Typography.Title>
          </Row>
          <Divider style={{ borderTopColor: '#E8E8E8', marginBlock: 5 }} />
          <Row>
            <Col span={6}>
              <Typography.Title level={5}>Kecil</Typography.Title>
              <InputNumber
                onChange={(value) => handlePriceChange('small', value)}
                addonBefore="Rp"
                value={prices?.small.value}
                style={{ width: '90%' }}
                step={500}
                formatter={(value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                parser={(value) => value.replace(/\$\s?|(\.*)/g, '')}
              />
            </Col>
            <Col span={6}>
              <Typography.Title level={5}>Sedang</Typography.Title>
              <InputNumber
                onChange={(value) => handlePriceChange('medium', value)}
                addonBefore="Rp"
                value={prices?.medium.value}
                step={500}
                style={{ width: '90%' }}
                formatter={(value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                parser={(value) => value.replace(/\$\s?|(\.*)/g, '')}
              />
            </Col>
            <Col span={6}>
              <Typography.Title level={5}>Besar</Typography.Title>
              <InputNumber
                onChange={(value) => handlePriceChange('large', value)}
                addonBefore="Rp"
                value={prices?.large.value}
                step={500}
                style={{ width: '90%' }}
                formatter={(value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                parser={(value) => value.replace(/\$\s?|(\.*)/g, '')}
              />
            </Col>
            <Col span={6} style={{ display: 'flex', alignItems: 'end' }}>
              <Button type="primary" onClick={handleConfirmUpdatePrice} loading={loading?.price}>
                Simpan perubahan
              </Button>
            </Col>
          </Row>
        </Space>
      </Layout.Content>
      <Layout.Content className="content-container">
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Row>
            {/* <DatePicker.RangePicker
              placeholder={['Dari tanggal...', 'Hingga tanggal...']}
              onChange={(_, string) => {
                setFilter({ ...filter, start_date: string[0], end_date: string[1] });
              }}
            /> */}
            <Button
              style={{ marginLeft: 'auto', color: '#1890FF', borderColor: '#1890FF' }}
              icon={<PlusOutlined />}
              onClick={() => history.push('/iklan/add')}>
              Tambah Iklan
            </Button>
          </Row>
          <Table
            loading={loading.data}
            columns={columns}
            pagination={{
              position: ['bottomLeft'],
              current: page,
              pageSize: limit,
              total: data?.total ?? 0,
              onChange: (page) => setPage(page),
              onShowSizeChange: (x, size) => setLimit(size)
            }}
            dataSource={data?.data ?? []}
          />
        </Space>
      </Layout.Content>
    </Layout>
  );
}
