import { requestAPI } from './Request';

export function uploadMedia({ data, purpose }) {
  let formData = new FormData();
  formData.append('file', data);
  return requestAPI.post('/medias', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    params: {
      purpose
    }
  });
}

export function uploadMediaBerita(data) {
  let formData = new FormData();
  formData.append('file', data);
  return requestAPI.post('/medias', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    params: {
      purpose: 'news'
    }
  });
}

export function uploadMediaProfile(data) {
  let formData = new FormData();
  formData.append('file', data);
  return requestAPI.post('/medias', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    params: {
      purpose: 'profile'
    }
  });
}

export function uploadMediaKTP(data) {
  let formData = new FormData();
  formData.append('file', data);
  return requestAPI.post('/medias', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    params: {
      purpose: 'ktp'
    }
  });
}

export function uploadMediaGallery(data) {
  let formData = new FormData();
  formData.append('file', data);
  return requestAPI.post('/medias', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    params: {
      purpose: 'gallery'
    }
  });
}

export function uploadMediaWYSIWYG(data) {
  let formData = new FormData();
  formData.append('file', data);
  return requestAPI.post('/medias', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    params: {
      purpose: 'wysiwyg'
    }
  });
}

export function uploadMediaConsultant(data) {
  let formData = new FormData();
  formData.append('file', data);
  return requestAPI.post('/medias', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    params: {
      purpose: 'consultant'
    }
  });
}
