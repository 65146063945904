import { Cookies } from 'react-cookie';
import { PrivateLayout } from 'layout';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import { PUBLIC_ROUTES } from './routes';

export default function App() {
  const cookies = new Cookies();
  const user = cookies.get('user');

  return (
    <Router>
      {user?.token ? (
        <PrivateLayout />
      ) : (
        <Switch>
          <Route exact {...PUBLIC_ROUTES} />
          <Redirect from="/" to="/" />
        </Switch>
      )}
    </Router>
  );
}
