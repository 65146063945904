import { requestAPI } from './Request';

export function getRoles() {
  return requestAPI.get('/roles');
}

export function getRole(id) {
  return requestAPI.get(`/roles/${id}`);
}

export function addRole(data) {
  return requestAPI.post(`/roles`, data);
}

export function updateRole(data) {
  return requestAPI.patch(`/roles/${data.id}`, data);
}

export function deleteRole(id) {
  return requestAPI.delete(`/roles/${id}`);
}
