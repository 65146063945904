import { SearchOutlined } from '@ant-design/icons/lib/icons';
import { Button, Input, Layout, Row, Space, Table, DatePicker } from 'antd';
import { Header } from 'component';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getNews } from 'services/ServiceNews';
import { DEFAULT_CATEGORIES_ID } from 'constants';
import { STATUS_NEWS } from 'constants/status';

import './styles.scss';

export default function ManageProKontra() {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState({
    title: '',
    start_date: undefined,
    end_date: undefined
  });
  let debounce = undefined;

  const [data, setData] = useState();

  useEffect(() => {
    debounce = setTimeout(() => {
      fetchData();
    }, 300);

    return () => clearTimeout(debounce);
  }, [page, limit, filter]);

  async function fetchData() {
    const result = await getNews({
      page,
      limit,
      category_id: DEFAULT_CATEGORIES_ID.surat_pengirim,
      ...filter
    });
    setData(result.data?.data);
  }

  const columns = [
    {
      title: 'Judul',
      dataIndex: 'title',
      key: 'id',
      width: '40%'
    },
    {
      title: 'Dibuat oleh',
      key: 'id',
      width: '15%',
      render: (data) => data?.created_by?.email
    },
    {
      title: 'Ditujukan ke',
      key: 'id',
      width: '15%',
      render: (data) => data?.for_user?.name
    },
    {
      title: 'Status',
      key: 'id',
      render: (data) => (
        <div style={{ color: STATUS_NEWS?.[data?.status]?.color }}>
          {STATUS_NEWS?.[data?.status]?.label}
        </div>
      )
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'action',
      width: '150px',
      render: (id) => (
        <Button type="primary" onClick={() => history.push(`/kotak-surat/${id}`)}>
          Lihat
        </Button>
      )
    }
  ];

  return (
    <Layout className="container">
      <Header label="Kelola Berita Kotak Surat" />
      <Layout.Content className="content-container">
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Row>
            <Input
              style={{ width: '25%', marginRight: '2rem' }}
              placeholder="Cari judul berita..."
              prefix={<SearchOutlined />}
              onChange={(e) => setFilter({ ...filter, title: e.target.value })}
            />
            <DatePicker.RangePicker
              placeholder={['Dari tanggal...', 'Hingga tanggal...']}
              onChange={(_, string) => {
                setFilter({ ...filter, start_date: string[0], end_date: string[1] });
              }}
            />
          </Row>
          <Table
            columns={columns}
            pagination={{
              position: ['bottomLeft'],
              current: page,
              pageSize: limit,
              total: data?.total ?? 0,
              onChange: (page) => setPage(page),
              onShowSizeChange: (x, size) => setLimit(size)
            }}
            dataSource={data?.data ?? []}
          />
        </Space>
      </Layout.Content>
    </Layout>
  );
}
