import { useContext, useState } from 'react';
import { Breadcrumb, Button, Col, Form, Input, Layout, Row, Modal, Upload } from 'antd';
import './styles.scss';

import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import { addGallery, uploadMediaGallery } from 'services';
import { Header, Required } from 'component';
import { UserContext } from 'contexts';
import { formatUploadState } from 'utils';

export default function AddGallery() {
  const history = useHistory();
  const { userData } = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState('');
  const [image, setImage] = useState('');
  const [uploadState, setUploadState] = useState('EMPTY');

  function validate() {
    if (!name) {
      Modal.error({
        title: 'Error',
        content: 'Nama harus diisi',
        maskClosable: true
      });
    } else if (!image) {
      Modal.error({
        title: 'Error',
        content: 'Gambar harus diisi',
        maskClosable: true
      });
    } else {
      return true;
    }
  }

  function handleBeforeUpload(file) {
    if (file.size / 1024 / 1024 <= 4) {
      return false;
    }
    Modal.error({
      title: 'Error',
      content: `Ukuran File lebih besar dari 4 MB`,
      maskClosable: true
    });

    return true;
  }

  async function handleSubmit() {
    setLoading(true);
    if (validate()) {
      const result = await addGallery({
        name,
        path: image,
        user_id: userData.id
      });
      if (result.status >= 200 && result.status < 300)
        Modal.success({
          title: 'Galeri berhasil ditambahkan',
          content: `${name} telah berhasil ditambahkan`,
          maskClosable: true,
          afterClose: () => history.push('/gallery')
        });
      else
        Modal.error({
          title: 'Galeri gagal ditambahkan',
          content: 'Mohon periksa kembali data yang kamu input pada form',
          maskClosable: true
        });
    }
    setLoading(false);
  }

  return (
    <Layout className="container">
      <Header label="Tambah Galeri" allowBack />
      <Breadcrumb className="breadcrumb-container">
        <Breadcrumb.Item>Kelola Galeri</Breadcrumb.Item>
        <Breadcrumb.Item>Tambah Galeri</Breadcrumb.Item>
      </Breadcrumb>
      <Layout.Content style={{ margin: '20px' }}>
        <Form className="form-container" layout="vertical" onFinish={handleSubmit} size="large">
          <Row gutter={24}>
            <Col lg={12} style={{ marginBottom: '1rem' }}>
              <Form.Item
                label={
                  <>
                    Nama Galeri
                    <Required />
                  </>
                }>
                <Input
                  placeholder="Masukkan nama.."
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item
                label={
                  <>
                    Gambar
                    <Required />
                  </>
                }>
                <Row style={{ alignItems: 'center' }}>
                  <Upload
                    listType="picture"
                    accept=".jpg,.jpeg,.png"
                    maxCount={1}
                    beforeUpload={handleBeforeUpload}
                    onChange={(e) => {
                      setUploadState('LOADING');
                      if (e.file.status === 'removed') {
                        setUploadState('EMPTY');
                      } else {
                        uploadMediaGallery(e.file)
                          .then((res) => {
                            if (res.data?.data) {
                              setImage(res.data.data?.path);
                            }
                          })
                          .catch(() => {
                            setImage('');
                            Modal.error({
                              title: 'Galeri gagal ditambahkan',
                              content: 'Mohon periksa kembali dan upload ulang gambarnya',
                              maskClosable: true
                            });
                          })
                          .finally(() => {
                            setUploadState('OCCUPIED');
                          });
                      }
                    }}
                    onRemove={() => {
                      setImage('');
                    }}
                    style={{ width: '550px', maxWidth: '550px' }}>
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  </Upload>
                  <span style={{ padding: '0 18px', color: '#BDBDBD' }}>
                    {formatUploadState(uploadState)}
                  </span>
                </Row>
              </Form.Item>
            </Col>
            <Col lg={24}>
              <Form.Item>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  size="large"
                  htmlType="submit"
                  loading={loading}>
                  Tambah Galeri
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Layout.Content>
    </Layout>
  );
}
