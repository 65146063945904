import { useEffect, useState } from 'react';
import { Breadcrumb, Button, Image, Layout, Row, Typography } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { Header } from 'component';
import './styles.scss';
import { getUserDetail, verifyUser } from 'services';
import { CheckOutlined } from '@ant-design/icons/lib/icons';

export default function VerifyUser() {
  const { id } = useParams();
  const history = useHistory();
  const [data, setData] = useState();

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    const result = await getUserDetail(id);

    setData(result.data?.data);
  }

  function handleVerify() {
    verifyUser(id).then(() => history.push(`/users/${id}`));
  }
  return (
    <Layout className="container">
      <Header label={data?.name ?? '-'} allowBack />
      <Breadcrumb className="breadcrumb-container">
        <Breadcrumb.Item>Kelola User</Breadcrumb.Item>
        <Breadcrumb.Item>{data?.name ?? '-'}</Breadcrumb.Item>
        <Breadcrumb.Item>Verifikasi KTP</Breadcrumb.Item>
      </Breadcrumb>
      <Layout.Content className="content-container">
        <Row style={{ marginBlock: 20 }}>
          <Image width={600} src={data?.ktp_picture} />
        </Row>
        <Row>
          <Typography.Title level={5} style={{ margin: 0 }}>
            Verifikasi Kartu Identitas (KTP)?
          </Typography.Title>
        </Row>
        <Row>Apakah anda yakin ingin memverifikasi Kartu Identitas (KTP) milik {data?.name}?</Row>
        <Row style={{ marginBlock: 20 }}>
          <Button type="primary" icon={<CheckOutlined />} size="large" onClick={handleVerify}>
            Verifikasi
          </Button>
          <Button
            type="text"
            size="large"
            onClick={() => history.goBack()}
            style={{ marginLeft: '1rem', color: '#3F8FE7' }}>
            BATAL
          </Button>
        </Row>
      </Layout.Content>
    </Layout>
  );
}
