import { useContext, useState } from 'react';
import { Breadcrumb, Button, Col, Form, Input, Layout, Row, Modal, Typography } from 'antd';
import './styles.scss';

import { useHistory } from 'react-router-dom';

import { changePassword } from 'services';
import { Header, Required } from 'component';
import { UserContext } from 'contexts';

export default function AddGallery() {
  const history = useHistory();
  const { userData } = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  function validate() {
    if (!oldPassword) {
      Modal.error({
        title: 'Error',
        content: 'password lama harus diisi',
        maskClosable: true
      });
    } else if (!newPassword) {
      Modal.error({
        title: 'Error',
        content: 'password baru harus diisi',
        maskClosable: true
      });
    } else if (newPassword.length < 8) {
      Modal.error({
        title: 'Error',
        content: 'panjang minimal password 8 karakter',
        maskClosable: true
      });
    } else if (
      !/[a-z]+/.test(newPassword) ||
      !/[A-Z]+/.test(newPassword) ||
      !/[0-9]+/.test(newPassword)
    ) {
      Modal.error({
        title: 'Error',
        content: 'password harus terdiri atas huruf besar dan kecil dan angka',
        maskClosable: true
      });
    } else if (!confirmPassword) {
      Modal.error({
        title: 'Error',
        content: 'password konfirmasi harus diisi',
        maskClosable: true
      });
    } else if (newPassword != confirmPassword) {
      Modal.error({
        title: 'Error',
        content: 'password baru harus sama dengan konfirmasi',
        maskClosable: true
      });
    } else {
      return true;
    }
  }

  async function handleSubmit() {
    setLoading(true);
    if (validate()) {
      const result = await changePassword({
        id: userData.id,
        old_password: oldPassword,
        password: newPassword,
        password_confirmation: confirmPassword
      });
      if (result.status >= 200 && result.status < 300)
        Modal.success({
          title: 'Password berhasil diubah',
          content: `Kamu berhasil mengubah password`,
          maskClosable: true,
          afterClose: () => history.push('/home')
        });
      else
        Modal.error({
          title: 'Gagal mengubah password',
          content: 'Mohon periksa kembali data yang kamu input pada form',
          maskClosable: true
        });
    }
    setLoading(false);
  }

  return (
    <Layout className="container">
      <Header label="Ubah Password" allowBack />
      <Breadcrumb className="breadcrumb-container">
        <Breadcrumb.Item>Ubah Password</Breadcrumb.Item>
      </Breadcrumb>
      <Layout.Content style={{ margin: '20px' }}>
        <Form className="form-container" layout="vertical" onFinish={handleSubmit} size="large">
          <Row gutter={24}>
            <Col lg={12} style={{ marginBottom: '1rem' }}>
              <Form.Item
                label={
                  <>
                    Password lama
                    <Required />
                  </>
                }>
                <Input
                  placeholder="Masukkan password lama.."
                  type="password"
                  value={oldPassword}
                  onChange={(e) => {
                    setOldPassword(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col lg={12} style={{ marginBottom: '1rem' }}>
              <Form.Item
                label={
                  <>
                    Password baru
                    <Required />
                  </>
                }>
                <Input
                  placeholder="Masukkan password baru.."
                  type="password"
                  value={newPassword}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col lg={12} style={{ marginBottom: '1rem' }}>
              <Form.Item
                label={
                  <>
                    Konfirmasi password baru
                    <Required />
                  </>
                }>
                <Input
                  placeholder="Konfirmasi password baru.."
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                />
                <Typography.Text type="secondary">
                  * Pastikan password kamu terdiri dari minimal 8 karakter, huruf besar, huruf kecil
                  dan angka
                </Typography.Text>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button type="primary" size="large" htmlType="submit" loading={loading}>
              Konfirmasi
            </Button>
          </Form.Item>
        </Form>
      </Layout.Content>
    </Layout>
  );
}
