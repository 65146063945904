import { requestAPI } from './Request';

export function getGalleries(data) {
  return requestAPI.get('/medias/admin', { params: data });
}

export function addGallery(data) {
  return requestAPI.post(`/medias/galleries/admin`, data);
}

export function deleteGallery(id) {
  return requestAPI.delete(`/medias/${id}`);
}

export function verifGallery(id) {
  return requestAPI.get(`/medias/verif/${id}`);
}

export function unverifGallery(id) {
  return requestAPI.get(`/medias/unverif/${id}`);
}
