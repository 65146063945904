/* eslint-disable react/prop-types */
import { Layout } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons/lib/icons';
import { useHistory } from 'react-router-dom';
import './styles.scss';

export default function Header({ label, allowBack = false }) {
  const history = useHistory();
  return (
    <Layout.Header className="header">
      {allowBack && <ArrowLeftOutlined className="arrow-icon" onClick={() => history.goBack()} />}
      <div className="layout-header">{label}</div>
    </Layout.Header>
  );
}
