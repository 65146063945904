import moment from 'moment';
import { DEFAULT_CATEGORIES_ID_ARRAY } from 'constants';

export function DateFormatter(date) {
  return date ? moment(date).format('DD MMMM YYYY') : '-';
}

export function PruneDefaultCategories(data) {
  return data.filter((value) => !DEFAULT_CATEGORIES_ID_ARRAY.some((id) => id == value.id));
}
