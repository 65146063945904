import { PlusOutlined, SearchOutlined } from '@ant-design/icons/lib/icons';
import { Breadcrumb, Button, Input, Layout, Modal, Row, Space, Table } from 'antd';
import { Header } from 'component';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './styles.scss';
import { deleteStructure, getStructures } from 'services/ServiceStructure';

export default function ManageStructure() {
  const history = useHistory();
  const [filter, setFilter] = useState('');
  let debounce = undefined;

  const [data, setData] = useState();
  const [tblData, setTblData] = useState([]);

  useEffect(() => {
    debounce = setTimeout(() => {
      fetchData();
    }, 300);

    return () => clearTimeout(debounce);
  }, []);

  useEffect(() => {
    debounce = setTimeout(() => {
      if (data?.data) {
        const mutatedData = data?.data?.slice() || [];
        if (filter) {
          setTblData(mutatedData.filter((strData) => String(strData?.name).includes(filter)));
        } else {
          setTblData(mutatedData);
        }
      }
    }, 300);

    return () => clearTimeout(debounce);
  }, [filter, data?.data]);

  async function fetchData() {
    const result = await getStructures();
    setData(result?.data);
  }

  function openModal(data) {
    Modal.confirm({
      title: 'Hapus data ini?',
      content: `Apakah kamu yakin ingin menghapus data ${data.name}?`,
      onOk() {
        deleteStructure(data.id)
          .then(() => {
            Modal.success({
              title: 'Data berhasil dihapus',
              content: `Kamu berhasil menghapus data ${data.name}`,
              afterClose: () => fetchData()
            });
          })
          .catch(() => {
            Modal.error({
              title: 'Data gagal dihapus',
              content: `Data tidak bisa dihapus, mohon coba lagi nanti`
            });
          });
      }
    });
  }

  const columns = [
    {
      title: 'Nama Anggota',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Posisi',
      dataIndex: 'position',
      key: 'position'
    },
    {
      title: 'Tim Personalia Redaksi',
      dataIndex: 'is_main_team',
      key: 'id',
      width: '250px',
      render: (is_main_team) => (
        <>{Number(is_main_team) ? 'Tim Personalia Redaksi' : 'Tim Perwakilan'}</>
      ),
      filters: [
        { text: 'Tim Personalia Redaksi', value: '1' },
        { text: 'Tim Perwakilan', value: '0' }
      ],
      onFilter: (value, record) => record.is_main_team.indexOf(value) === 0
    },
    {
      title: 'Action',
      key: 'action',
      width: '300px',
      render: (row) => (
        <Space>
          <Button
            type="primary"
            onClick={() => {
              history.push(`/structure/${row?.id}`);
            }}>
            Update
          </Button>
          <Button type="ghost" danger onClick={() => openModal(row)}>
            Hapus
          </Button>
        </Space>
      )
    }
  ];

  return (
    <Layout className="container">
      <Header label="Kelola Struktur Organisasi" />
      <Breadcrumb className="breadcrumb-container">
        <Breadcrumb.Item>Kelola Struktur Organisasi</Breadcrumb.Item>
      </Breadcrumb>
      <Layout.Content className="content-container">
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Row>
            <Input
              style={{ width: '25%' }}
              placeholder="Cari anggota..."
              prefix={<SearchOutlined />}
              onChange={(e) => setFilter(e.target.value)}
            />
            <Button
              style={{ marginLeft: 'auto', color: '#1890FF', borderColor: '#1890FF' }}
              icon={<PlusOutlined />}
              onClick={() => history.push('/structure/add')}>
              Tambah anggota
            </Button>
          </Row>
          <Table
            columns={columns}
            pagination={{
              position: ['bottomLeft'],
              pageSize: 15,
              total: tblData?.length ?? 0
            }}
            dataSource={tblData ?? []}
          />
        </Space>
      </Layout.Content>
    </Layout>
  );
}
