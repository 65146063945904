import { useEffect, useState } from 'react';
import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Input,
  Layout,
  Row,
  Modal,
  Checkbox,
  Typography,
  Select
} from 'antd';
import './styles.scss';

import { PlusOutlined } from '@ant-design/icons/lib/icons';
import { useParams, useHistory } from 'react-router-dom';

import { addRole, getRole, updateRole } from 'services';
import { Header, Required } from 'component';
import { ADMIN_MENU } from 'constants/admin';

export default function AddRole() {
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState('');
  const [isAdmin, setIsAdmin] = useState(true);
  const [menu, setMenu] = useState([]);

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, []);

  async function fetchData() {
    const result = await getRole(id);

    const data = result.data?.data;

    if (data) {
      setName(data.name);
      setIsAdmin(data.is_admin);
      setMenu(
        JSON.parse(data.available_menu).filter((value) =>
          ADMIN_MENU.some((menu) => menu['id'] == value)
        )
      );
    }
  }

  function validate() {
    if (!name) {
      Modal.error({
        title: 'Error',
        content: 'Nama harus diisi',
        maskClosable: true
      });
    } else {
      return true;
    }
  }

  async function handleSubmit() {
    setLoading(true);
    if (validate()) {
      const result = id
        ? await updateRole({
            id,
            name,
            is_admin: isAdmin,
            available_menu: JSON.stringify(menu)
          })
        : await addRole({
            name,
            is_admin: isAdmin,
            available_menu: JSON.stringify(menu)
          });
      if (result.status >= 200 && result.status < 300)
        Modal.success({
          title: id ? 'Data berhasil diubah' : 'Role berhasil ditambahkan',
          content: id ? 'Data role telah berhasil diubah' : `${name} telah berhasil ditambahkan`,
          maskClosable: true,
          afterClose: () => history.push('/roles')
        });
      else
        Modal.error({
          title: id ? 'Data gagal diubah' : 'Role gagal ditambahkan',
          content: 'Mohon periksa kembali data yang kamu input pada form',
          maskClosable: true
        });
    }
    setLoading(false);
  }

  return (
    <Layout className="container">
      <Header label={id ? 'Ubah Role' : 'Tambah Role'} allowBack />
      <Breadcrumb className="breadcrumb-container">
        <Breadcrumb.Item>Kelola Roles</Breadcrumb.Item>
        <Breadcrumb.Item>{id ? 'Ubah Role' : 'Tambah Role'}</Breadcrumb.Item>
      </Breadcrumb>
      <Layout.Content style={{ margin: '20px' }}>
        <Form className="form-container" layout="vertical" onFinish={handleSubmit} size="large">
          <Row gutter={24}>
            <Col lg={12} style={{ marginBottom: '1rem' }}>
              <Form.Item
                label={
                  <>
                    Nama
                    <Required />
                  </>
                }>
                <Input
                  placeholder="Masukkan nama.."
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </Form.Item>
              <Checkbox
                checked={isAdmin ?? false}
                onClick={() => setIsAdmin(!isAdmin)}
                disabled={name == 'superAdmin'}>
                <Typography.Text>Role Admin</Typography.Text>
              </Checkbox>
            </Col>
            <Col lg={12} style={{ marginBottom: '1rem' }}>
              {isAdmin && (
                <Form.Item label="Menu yang dapat diakses">
                  <Select
                    mode="multiple"
                    value={menu}
                    placeholder="Pilih menu..."
                    onChange={(value) => setMenu(value)}>
                    {ADMIN_MENU.map((item) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </Col>
            <Col lg={24}>
              <Form.Item>
                <Button
                  type="primary"
                  icon={!id && <PlusOutlined />}
                  size="large"
                  htmlType="submit"
                  loading={loading}>
                  {id ? 'Ubah role' : 'Tambah role'}
                </Button>
                {id && (
                  <Button
                    type="text"
                    size="large"
                    onClick={() => history.goBack()}
                    style={{ marginLeft: '1rem', color: '#3F8FE7' }}>
                    BATAL
                  </Button>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Layout.Content>
    </Layout>
  );
}
