import { requestAPI } from './Request';

export function getKonsultasiHukum() {
  return requestAPI.get('/consultants');
}

export function getKonsultasiHukumDetail(id) {
  return requestAPI.get(`/consultants/${id}`);
}

export function addKonsultasiHukum(payload) {
  return requestAPI.post('/consultants', payload);
}

export function updateKonsultasiHukum(payload, id) {
  return requestAPI.post(`/consultants/${id}`, payload);
}

export function deleteKonsultasiHukum(id) {
  return requestAPI.delete(`/consultants/${id}`);
}
