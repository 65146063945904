import { Card, Col, Layout, Row, Space, Typography } from 'antd';
import { Header } from 'component';
import { useState } from 'react';
import { useEffect } from 'react';
import { getDashboard } from 'services/ServiceDashboard';
import './styles.scss';

const COLORS = ['#69A5F8', '#73D8BE', '#EE7B8B', '#EE7B8B'];

export default function Home() {
  const [dashboardData, setDashboardData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    const { data } = await getDashboard();
    setDashboardData(data?.data);
    console.log(data);
  }

  return (
    <Layout className="container">
      <Header label="Dashboard" />
      <Layout.Content style={{ margin: 16 }}>
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Row gutter={16}>
            {dashboardData?.map((dashData, index) => (
              <Col span={6} key={index}>
                <Card
                  bordered={false}
                  className="dashboard-counter"
                  style={{ backgroundColor: COLORS[index % COLORS.length] }}>
                  <Typography.Title level={4} className="counter-title">
                    {dashData?.label}
                  </Typography.Title>
                  <Typography.Title level={2} className="counter-value">
                    {dashData?.value}
                  </Typography.Title>
                </Card>
              </Col>
            ))}
          </Row>
        </Space>
      </Layout.Content>
    </Layout>
  );
}
