import { useEffect, useState } from 'react';
import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Input,
  Layout,
  Row,
  Select,
  Upload,
  Modal,
  Checkbox
} from 'antd';
import './styles.scss';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons/lib/icons';
import { useParams, useHistory } from 'react-router-dom';

import {
  addUser,
  getRoles,
  getUserDetail,
  updateUser,
  uploadMediaKTP,
  uploadMediaProfile
} from 'services';
import { Header, Required } from 'component';
import { formatUploadState } from 'utils';

export default function AddUser() {
  const { id } = useParams();
  const history = useHistory();
  const [roleData, setRoleData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [role, setRole] = useState();
  const [imageProfile, setImageProfile] = useState('');
  const [imageKtp, setImageKtp] = useState('');
  const [isOfficial, setIsOfficial] = useState(false);
  const [allowedType, setAllowedType] = useState(id ? true : false);
  const [profileState, setProfileState] = useState('EMPTY');
  const [ktpState, setKtpState] = useState('EMPTY');

  useEffect(() => {
    if (id) {
      fetchData();
    }
    fetchRoleData();
  }, []);

  async function fetchData() {
    const result = await getUserDetail(id);

    const data = result.data?.data;

    if (data) {
      setName(data.name);
      setEmail(data.email);
      setPhone(data.phone);
      setRole(data.role?.id);
      setImageProfile(data.profile_picture);
      setImageKtp(data.ktp_picture);
      setIsOfficial(Boolean(data?.is_official));
      console.log('AAA', data);
      data.profile_picture && setProfileState('OCCUPIED');
      data.ktp_picture && setKtpState('OCCUPIED');
    }
  }

  async function fetchRoleData() {
    const result = await getRoles();

    if (result.data) {
      setRoleData(result.data.data);
    }
  }

  function validate() {
    if (!name) {
      Modal.error({
        title: 'Error',
        content: 'Nama harus diisi',
        maskClosable: true
      });
    } else if (!email) {
      Modal.error({
        title: 'Error',
        content: 'Email harus diisi',
        maskClosable: true
      });
    } else if (!phone) {
      Modal.error({
        title: 'Error',
        content: 'Nomor telepon harus diisi',
        maskClosable: true
      });
    } else if (!role) {
      Modal.error({
        title: 'Error',
        content: 'Role harus diisi',
        maskClosable: true
      });
    } else if (imageProfile && !allowedType) {
      Modal.error({
        title: 'Error',
        content: 'Tipe file gambar tidak diperbolehkan',
        maskClosable: true
      });
    } else {
      return true;
    }
  }

  function handleBeforeUpload(file) {
    if (file.type == 'image/jpeg' || file.type == 'image/jpg') {
      if (file.size / 1024 / 1024 <= 4) {
        setAllowedType(true);
        return false;
      }
      Modal.error({
        title: 'Error',
        content: `Ukuran File lebih besar dari 4 MB`,
        maskClosable: true
      });

      return true;
    } else {
      Modal.error({
        title: 'Error',
        content: `${file.name} tidak bertipe jpg or jpeg `,
        maskClosable: true
      });

      setAllowedType(false);
      return true;
    }
  }

  async function handleSubmit() {
    setLoading(true);
    if (validate()) {
      const result = id
        ? await updateUser({
            id,
            name,
            email,
            phone,
            role_id: role,
            profile_picture: imageProfile || undefined,
            ktp_picture: imageKtp || undefined,
            is_official: isOfficial
          })
        : await addUser({
            name,
            email,
            phone,
            role_id: role,
            profile_picture: imageProfile || undefined,
            ktp_picture: imageKtp || undefined,
            is_official: isOfficial
          });
      if (result.status >= 200 && result.status < 300)
        Modal.success({
          title: id ? 'Data berhasil diubah' : 'User berhasil ditambahkan',
          content: id
            ? 'Data user telah berhasil diubah'
            : 'User yang kamu input telah berhasil ditambahkan',
          maskClosable: true,
          afterClose: () => history.push('/users')
        });
      else
        Modal.error({
          title: id ? 'Data gagal diubah' : 'User gagal ditambahkan',
          content: 'Mohon periksa kembali data yang kamu input pada form',
          maskClosable: true
        });
    }
    setLoading(false);
  }

  return (
    <Layout className="container">
      <Header label={id ? 'Ubah Data User' : 'Tambah User'} allowBack />
      <Breadcrumb className="breadcrumb-container">
        <Breadcrumb.Item>Kelola User</Breadcrumb.Item>
        <Breadcrumb.Item>{id ? 'Ubah Data User' : 'Tambah User'}</Breadcrumb.Item>
      </Breadcrumb>
      <Layout.Content style={{ margin: '20px' }}>
        <Form className="form-container" layout="vertical" onFinish={handleSubmit} size="large">
          <Row gutter={24}>
            <Col lg={12}>
              <Form.Item
                label={
                  <>
                    Nama
                    <Required />
                  </>
                }>
                <Input
                  placeholder="Masukkan nama.."
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item
                label={
                  <>
                    Email
                    <Required />
                  </>
                }>
                <Input
                  placeholder="Masukkan email.."
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item
                label={
                  <>
                    Nomor Telepon
                    <Required />
                  </>
                }>
                <Input
                  placeholder="Masukkan nomor telepon.."
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value.replace(/[^0-9]/g, ''));
                  }}
                />
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item label="Foto Profil (opsional)">
                <Row style={{ alignItems: 'center' }}>
                  <Upload
                    listType="picture"
                    accept="jpg, jpeg"
                    maxCount={1}
                    beforeUpload={handleBeforeUpload}
                    onChange={(e) => {
                      setProfileState('LOADING');
                      if (e.file.status === 'removed') {
                        setProfileState('EMPTY');
                      } else {
                        uploadMediaProfile(e.file)
                          .then((res) => {
                            if (res.data?.data) {
                              setImageProfile(res.data.data?.path);
                            }
                          })
                          .finally(() => {
                            setProfileState('OCCUPIED');
                          });
                      }
                    }}
                    onRemove={() => {
                      setImageProfile('');
                    }}
                    style={{ width: '550px', maxWidth: '550px' }}>
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  </Upload>
                  <span style={{ padding: '0 18px', color: '#BDBDBD' }}>
                    {formatUploadState(profileState)}
                  </span>
                </Row>
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item
                label={
                  <>
                    Role
                    <Required />
                  </>
                }>
                <Select placeholder="Pilih role..." value={role} onChange={(e) => setRole(e)}>
                  {roleData.map(({ id, name }) => (
                    <Select.Option key={id} value={id}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item label="Kartu Identitas (opsional)">
                <Row style={{ alignItems: 'center' }}>
                  <Upload
                    listType="picture"
                    accept="jpg, jpeg"
                    maxCount={1}
                    beforeUpload={handleBeforeUpload}
                    onChange={(e) => {
                      setKtpState('LOADING');
                      if (e.file.status === 'removed') {
                        setKtpState('EMPTY');
                      } else {
                        uploadMediaKTP(e.file)
                          .then((res) => {
                            if (res.data?.data) {
                              setImageKtp(res.data.data?.path);
                            }
                          })
                          .finally(() => {
                            setKtpState('OCCUPIED');
                          });
                      }
                    }}
                    onRemove={() => {
                      setImageKtp('');
                    }}
                    style={{ width: '550px', maxWidth: '550px' }}>
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  </Upload>
                  <span style={{ padding: '0 18px', color: '#BDBDBD' }}>
                    {formatUploadState(ktpState)}
                  </span>
                </Row>
              </Form.Item>
            </Col>
            <Col lg={12}>
              <div style={{ marginBottom: '16px' }}>
                <Checkbox checked={isOfficial} onChange={(e) => setIsOfficial(e.target.checked)}>
                  Akun official
                </Checkbox>
              </div>
            </Col>

            <Col lg={24}>
              <Form.Item>
                <Button
                  type="primary"
                  icon={!id && <PlusOutlined />}
                  size="large"
                  htmlType="submit"
                  loading={loading}>
                  {id ? 'Ubah data user' : 'Tambah user'}
                </Button>
                {id && (
                  <Button
                    type="text"
                    size="large"
                    onClick={() => history.goBack()}
                    style={{ marginLeft: '1rem', color: '#3F8FE7' }}>
                    BATAL
                  </Button>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Layout.Content>
    </Layout>
  );
}
