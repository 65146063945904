import { useEffect, useState } from 'react';
import { Breadcrumb, Button, Col, Form, Input, Layout, Row, Modal, Tag, Popover } from 'antd';
import { TwitterPicker } from 'react-color';
import './styles.scss';

import { PlusOutlined } from '@ant-design/icons/lib/icons';
import { useParams, useHistory } from 'react-router-dom';

import { addCategory, getCategories, updateCategory } from 'services';
import { Header, Required } from 'component';

export default function AddCategory() {
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState('');
  const [color, setColor] = useState('');

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, []);

  async function fetchData() {
    const result = await getCategories();

    const data = result.data?.data?.find((item) => item.id == id);

    if (data) {
      setName(data.name);
      setColor(data.color.replace(/[^0-9a-f]/g, ''));
    }
  }

  function validate() {
    if (!name) {
      Modal.error({
        title: 'Error',
        content: 'Nama harus diisi',
        maskClosable: true
      });
    } else if (!color) {
      Modal.error({
        title: 'Error',
        content: 'Warna harus diisi',
        maskClosable: true
      });
    } else if (/[^0-9a-f]/g.test(color) || !(color.length == 3 || color.length == 6)) {
      Modal.error({
        title: 'Error',
        content: 'Warna harus berupa heksadesimal dengan panjang 3 atau 6 karakter',
        maskClosable: true
      });
    } else {
      return true;
    }
  }

  async function handleSubmit() {
    setLoading(true);
    if (validate()) {
      const result = id
        ? await updateCategory({
            id,
            name,
            color: `#${color}`
          })
        : await addCategory({
            name,
            color: `#${color}`
          });
      if (result.status >= 200 && result.status < 300)
        Modal.success({
          title: id ? 'Data berhasil diubah' : 'Kategori berhasil ditambahkan',
          content: id
            ? 'Data kategori telah berhasil diubah'
            : `${name} telah berhasil ditambahkan`,
          maskClosable: true,
          afterClose: () => history.push('/categories')
        });
      else
        Modal.error({
          title: id ? 'Data gagal diubah' : 'Kategori gagal ditambahkan',
          content: 'Mohon periksa kembali data yang kamu input pada form',
          maskClosable: true
        });
    }
    setLoading(false);
  }

  return (
    <Layout className="container">
      <Header label={id ? 'Ubah Kategori' : 'Tambah Kategori'} allowBack />
      <Breadcrumb className="breadcrumb-container">
        <Breadcrumb.Item>Kelola Kategori</Breadcrumb.Item>
        <Breadcrumb.Item>{id ? 'Ubah Kategori' : 'Tambah Kategori'}</Breadcrumb.Item>
      </Breadcrumb>
      <Layout.Content style={{ margin: '20px' }}>
        <Form className="form-container" layout="vertical" onFinish={handleSubmit} size="large">
          <Row gutter={24}>
            <Col lg={12} style={{ marginBottom: '1rem' }}>
              <Form.Item
                label={
                  <>
                    Nama Kategori
                    <Required />
                  </>
                }>
                <Input
                  placeholder="Masukkan nama.."
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col lg={12} style={{ marginBottom: '1rem' }}>
              <Form.Item
                label={
                  <>
                    Warna <Required />
                  </>
                }>
                <Popover
                  placement="bottomLeft"
                  content={
                    <TwitterPicker
                      triangle="top-left"
                      color={color}
                      onChangeComplete={(value) => setColor(value.hex.replace(/[^0-9a-f]/g, ''))}
                    />
                  }>
                  <Input
                    placeholder="Masukkan kode warna.."
                    maxLength={6}
                    addonBefore={
                      <span
                        style={{
                          display: 'flex',
                          flex: 1,
                          padding: 20,
                          backgroundColor: `#${color}`
                        }}
                      />
                    }
                    value={color}
                    onChange={(e) => setColor(e.target.value)}
                  />
                </Popover>
              </Form.Item>
            </Col>
            <Col lg={12} style={{ marginBottom: '1rem' }}>
              <Form.Item label="Simulasi Tampilan">
                <Tag
                  color={`#${color}` ?? undefined}
                  style={{ borderRadius: 12, border: 0, padding: '4px 12px', fontWeight: 600 }}>
                  {name ?? '-'}
                </Tag>
              </Form.Item>
            </Col>
            <Col lg={24}>
              <Form.Item>
                <Button
                  type="primary"
                  icon={!id && <PlusOutlined />}
                  size="large"
                  htmlType="submit"
                  loading={loading}>
                  {id ? 'Ubah kategori' : 'Tambah kategori'}
                </Button>
                {id && (
                  <Button
                    type="text"
                    size="large"
                    onClick={() => history.goBack()}
                    style={{ marginLeft: '1rem', color: '#3F8FE7' }}>
                    BATAL
                  </Button>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Layout.Content>
    </Layout>
  );
}
