import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Image,
  Input,
  Layout,
  Row,
  Select,
  Typography,
  Upload,
  Modal,
  Tag,
  Carousel
} from 'antd';
import ReactQuill from 'react-quill';

import './styles.scss';
import { PlusOutlined, InboxOutlined } from '@ant-design/icons/lib/icons';
import { useParams, useHistory } from 'react-router-dom';

import { getCategories, uploadMedia } from 'services';
import { addNews, getNewsDetail, updateNews } from 'services/ServiceNews';
import { PROVINCE } from 'constants';
import { Header, Required } from 'component';
import { PruneDefaultCategories } from 'utils';
import { IMAGE_PROPOSE } from 'constants';
import { DEFAULT_CATEGORIES_ID } from 'constants';

export default function AddNasional() {
  const { id } = useParams();
  const history = useHistory();
  const [dataSubcategory, setDataSubcategory] = useState([]);
  const [loading, setLoading] = useState(false);

  const [images, setImages] = useState([]);
  const [title, setTitle] = useState('');
  const [location, setLocation] = useState();
  const [subcategory, setSubcategory] = useState();
  const [content, setContent] = useState('');
  const [allowedType, setAllowedType] = useState(id ? true : false);
  const [defaultBannerList, setDefaultBannerList] = useState([]);
  const quillRef = useRef(null);

  useEffect(() => {
    fetchSubcategoriesData();
    if (id) {
      fetchData();
    }
  }, []);

  async function fetchSubcategoriesData() {
    const result = await getCategories();

    if (result.data) {
      setDataSubcategory(PruneDefaultCategories(result.data?.data));
    }
  }

  async function fetchData() {
    const result = await getNewsDetail(id);

    const data = result.data?.data;

    if (data) {
      setTitle(data?.title);
      const { banner_path } = data;
      const mutatedBanner = banner_path?.charAt(0) === '[' ? banner_path : `["${banner_path}"]`;
      setImages(
        JSON.parse(mutatedBanner)?.map((path) => ({
          name: String(path)?.slice(String(path)?.lastIndexOf('/') + 1),
          url: path
        }))
      );
      setLocation(data?.location);
      setContent(data?.content);
      if (data?.sub_categories?.length > 0) {
        const mutatedSub = data?.sub_categories?.map((ctg) => ctg?.id);
        setSubcategory(mutatedSub);
      } else {
        setSubcategory(undefined);
      }

      const bannerFiles = JSON.parse(mutatedBanner)?.map((path, index) => ({
        uid: index,
        name: String(path)?.slice(String(path)?.lastIndexOf('/') + 1)
      }));
      setDefaultBannerList(bannerFiles);
    }
  }

  function validate() {
    if (!title) {
      Modal.error({
        title: 'Error',
        content: 'Judul harus diisi',
        maskClosable: true
      });
    } else if (!location) {
      Modal.error({
        title: 'Error',
        content: 'Lokasi harus diisi',
        maskClosable: true
      });
    } else if (!content) {
      Modal.error({
        title: 'Error',
        content: 'Konten harus diisi',
        maskClosable: true
      });
    } else if (images?.length === 0) {
      Modal.error({
        title: 'Error',
        content: 'Gambar harus diisi',
        maskClosable: true
      });
    } else if (!allowedType) {
      Modal.error({
        title: 'Error',
        content: 'Tipe file gambar tidak diperbolehkan',
        maskClosable: true
      });
    } else {
      return true;
    }
  }

  function handleBeforeUpload(file) {
    if (file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/png') {
      if (file.size / 1024 / 1024 <= 4) {
        setAllowedType(true);
        return false;
      }
      Modal.error({
        title: 'Error',
        content: `Ukuran File lebih besar dari 4 MB`,
        maskClosable: true
      });

      return true;
    } else {
      Modal.error({
        title: 'Error',
        content: `${file.name} tidak bertipe jpg, jpeg, atau png `,
        maskClosable: true
      });

      setAllowedType(false);
      return true;
    }
  }

  function handleImageUploader(e) {
    if (e.file.status !== 'removed') {
      uploadMedia({ data: e.file, purpose: IMAGE_PROPOSE.NEWS }).then((res) => {
        if (res?.data?.data) {
          setImages((prev) => [
            ...prev,
            {
              name: e.file?.name,
              url: res.data.data?.path
            }
          ]);
          if (id) {
            setDefaultBannerList((prev) => [
              ...prev,
              {
                uid: e?.file?.uid,
                name: String(res?.data?.data?.path)?.slice(
                  String(res?.data?.data?.path)?.lastIndexOf('/') + 1
                ),
                url: res?.data?.data?.path,
                thumbUrl: res?.data?.data?.path
              }
            ]);
          }
        }
      });
    }
  }

  function handleImageUploaderRemove(file) {
    setImages(images?.filter((img) => img?.name !== file?.name));
    setDefaultBannerList((prev) => prev?.filter((img) => img?.name !== file?.name));
  }

  const wysiwygImageHandler = useCallback(() => {
    if (quillRef) {
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.click();
      input.onchange = async () => {
        const file = input.files[0];
        // Save current cursor state
        const quillEditor = quillRef?.current?.getEditor();
        const range = quillEditor.getSelection(true);
        // Insert temporary loading placeholder image
        quillEditor.insertEmbed(
          range.index,
          'image',
          `https://api.kabelins.com/storage/wysiwyg/loading.gif`
        );
        // Move cursor to right side of image (easier to continue typing)
        quillEditor.setSelection(range.index + 1);
        const res = await uploadMedia({ data: file, purpose: IMAGE_PROPOSE.WYSIWYG }); // API post, returns image location as string e.g. 'http://www.example.com/images/foo.png'
        // Remove placeholder image
        quillEditor.deleteText(range.index, 1);
        // Insert uploaded image
        // quillEditor.insertEmbed(range.index, 'image', res.body.image);
        quillEditor.insertEmbed(range.index, 'image', res?.data?.data?.path);
      };
    }
  }, []);

  async function handleSubmit() {
    setLoading(true);
    if (validate()) {
      let result = { status: 499 };
      if (id) {
        const formattedImage = images?.map((img) => img?.url);
        result = await updateNews({
          id,
          title,
          location,
          content,
          category_id: DEFAULT_CATEGORIES_ID.cek_fakta,
          category_detail_id: JSON.stringify(subcategory),
          banner_path: JSON.stringify(formattedImage)
        });
      } else {
        const formattedImage = images?.map((img) => img?.url);
        result = await addNews({
          title,
          location,
          content,
          category_id: DEFAULT_CATEGORIES_ID.cek_fakta,
          category_detail_id: JSON.stringify(subcategory),
          banner_path: JSON.stringify(formattedImage)
        });
      }
      if (result.status >= 200 && result.status < 300)
        Modal.success({
          title: 'Sukses',
          content: id ? 'Berhasil mengubah berita' : 'Berhasil menambahkan berita',
          maskClosable: true,
          afterClose: () => history.push(`/cek-fakta/${result.data.data?.id}`)
        });
      else
        Modal.error({
          title: 'Error',
          content: id ? 'Gagal mengubah berita' : 'Gagal menambahkan berita',
          maskClosable: true
        });
    }
    setLoading(false);
  }

  return (
    <Layout className="container">
      <Header label={id ? 'Ubah Berita Cek Fakta' : 'Tambah Berita Cek Fakta'} />
      <Breadcrumb className="breadcrumb-container">
        <Breadcrumb.Item>Kelola Berita Cek Fakta</Breadcrumb.Item>
        <Breadcrumb.Item>
          {id ? 'Ubah Berita Cek Fakta' : 'Tambah Berita Cek Fakta'}
        </Breadcrumb.Item>
      </Breadcrumb>
      <Layout.Content style={{ margin: '20px' }}>
        <Form className="form-container" layout="vertical" onFinish={handleSubmit} size="large">
          <Row gutter={24}>
            <Col lg={12}>
              <Form.Item
                label={
                  <>
                    Judul
                    <Required />
                  </>
                }>
                <Input
                  placeholder="Masukkan judul berita..."
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item
                label={
                  <>
                    Kategori
                    <Required />
                  </>
                }>
                <Select disabled defaultValue="default">
                  <Select.Option value="default">Cek Fakta</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item
                label={
                  <>
                    Lokasi
                    <Required />
                  </>
                }>
                <Select
                  placeholder="Pilih lokasi berita..."
                  onChange={(e) => {
                    setLocation(e);
                  }}
                  value={location}
                  showSearch>
                  {PROVINCE.map(({ id, nama }) => (
                    <Select.Option key={id} value={nama}>
                      {nama}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item label="Sub Kategori (opsional)">
                <Select
                  mode="multiple"
                  value={subcategory}
                  placeholder="Pilih subkategori..."
                  className="category-option"
                  onChange={(value) => setSubcategory(value)}>
                  {dataSubcategory.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      <Tag
                        color={item?.color}
                        style={{
                          borderRadius: 12,
                          border: 0,
                          padding: '4px 12px',
                          fontWeight: 600
                        }}>
                        {item.name ?? '-'}
                      </Tag>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item
                label={
                  <>
                    <Typography.Text>
                      Gambar
                      <Required />
                    </Typography.Text>
                  </>
                }>
                <Row style={{ alignItems: 'center' }}>
                  <Upload.Dragger
                    fileList={id && defaultBannerList}
                    listType="picture"
                    accept="jpg,jpeg"
                    maxCount={8}
                    beforeUpload={handleBeforeUpload}
                    onChange={handleImageUploader}
                    onRemove={handleImageUploaderRemove}
                    style={{ width: '550px', maxWidth: '550px' }}>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Upload gambar berita (Max 4MB)</p>
                    <p className="ant-upload-hint">
                      Format .jpg atau .jpeg
                      <br />
                      dimensi yang direkomendasikan 653 x 416px atau 1306 x 832px
                    </p>
                  </Upload.Dragger>
                </Row>
              </Form.Item>
            </Col>
            <Col span={24}>
              {Boolean(images?.length) && (
                <Carousel autoplay dotPosition="bottom" style={{ width: 653 }}>
                  {images?.map((img, index) => (
                    <div key={index}>
                      <Image
                        preview={false}
                        style={{ objectFit: 'cover' }}
                        width={653}
                        height={416}
                        src={img.url}
                      />
                    </div>
                  ))}
                </Carousel>
              )}
            </Col>
            <Col lg={24}>
              <Form.Item
                label={
                  <>
                    Isi Berita <Required />
                  </>
                }>
                <p style={{ fontSize: '11px', color: 'rgba(0, 0, 0, 0.45)' }}>
                  Notes: Ukuran yang direcommendasikan untuk diletakan diantara tulisan sebesar 653
                  x 416
                </p>
                <ReactQuill
                  ref={quillRef}
                  value={content}
                  onChange={setContent}
                  modules={{
                    toolbar: {
                      container: [
                        [{ header: [1, 2, 3, 4, false] }],
                        ['bold', 'italic', 'underline'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        [{ align: [] }],
                        ['image']
                      ],
                      handlers: {
                        image: wysiwygImageHandler
                      }
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item>
                <Button
                  type="primary"
                  icon={!id && <PlusOutlined />}
                  size="large"
                  htmlType="submit"
                  loading={loading}>
                  {id ? 'Ubah Berita' : 'Tambah Berita'}
                </Button>
                {id && (
                  <Button
                    type="text"
                    size="large"
                    onClick={() => history.goBack()}
                    style={{ marginLeft: '1rem', color: '#3F8FE7' }}>
                    BATAL
                  </Button>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Layout.Content>
    </Layout>
  );
}
