import { requestAPI } from './Request';

export function getUsers({ page = 1, limit = 10, email }) {
  return requestAPI.get(`/users`, {
    params: {
      page,
      limit,
      email
    }
  });
}

export function getUserDetail(id) {
  return requestAPI.get(`/users/${id}`);
}

export function addUser(data) {
  return requestAPI.post('/users', data);
}

export function updateUser(data) {
  return requestAPI.patch(`/users/${data.id}`, data);
}

export function verifyUser(id) {
  return requestAPI.put(`/users/${id}/verify`);
}

export function deleteUser(id) {
  return requestAPI.delete(`/users/${id}`);
}

export function changePassword(data) {
  return requestAPI.put(`/users/${data.id}/change-admin-password`, data);
}
