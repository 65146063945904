export const DEFAULT_CATEGORIES = [
  { id: '0ff493ef-244b-47a8-b899-911a16694db6', name: 'Nasional' },
  { id: 'd9a2b8d0-4909-4a22-9b04-8a48d30c07e9', name: 'Internasional' },
  { id: '88046d69-33b4-4270-8cb4-e88336cc42f2', name: 'Cek Fakta' },
  { id: '98109273-99ef-4704-aafd-758a81899ac9', name: 'Pro Kontra' },
  { id: '660ea151-b953-4f97-a51f-eae3c3c07aff', name: 'Opini' },
  { id: '6b5237e5-632d-4fd8-87c9-f83663e7f26a', name: 'Surat Pengirim' },
  { id: 'ef445f76-bdc0-4045-9ea2-7f4cb245155c', name: 'Tanggapan' }
];

export const DEFAULT_CATEGORIES_ID = {
  nasional: '0ff493ef-244b-47a8-b899-911a16694db6',
  internasional: 'd9a2b8d0-4909-4a22-9b04-8a48d30c07e9',
  cek_fakta: '88046d69-33b4-4270-8cb4-e88336cc42f2',
  pro_kontra: '98109273-99ef-4704-aafd-758a81899ac9',
  opini: '660ea151-b953-4f97-a51f-eae3c3c07aff',
  surat_pengirim: '6b5237e5-632d-4fd8-87c9-f83663e7f26a',
  tanggapan: 'ef445f76-bdc0-4045-9ea2-7f4cb245155c'
};

export const DEFAULT_CATEGORIES_ID_ARRAY = [
  '0ff493ef-244b-47a8-b899-911a16694db6',
  'd9a2b8d0-4909-4a22-9b04-8a48d30c07e9',
  '88046d69-33b4-4270-8cb4-e88336cc42f2',
  '98109273-99ef-4704-aafd-758a81899ac9',
  '660ea151-b953-4f97-a51f-eae3c3c07aff',
  '6b5237e5-632d-4fd8-87c9-f83663e7f26a',
  'ef445f76-bdc0-4045-9ea2-7f4cb245155c'
];
