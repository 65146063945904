import { useEffect, useState } from 'react';
import { Breadcrumb, Button, Col, Input, Layout, Row, Modal, Upload, Image } from 'antd';
import './styles.scss';

import { InboxOutlined } from '@ant-design/icons/lib/icons';
import { useParams, useHistory } from 'react-router-dom';

import {
  addKonsultasiHukum,
  getKonsultasiHukumDetail,
  updateKonsultasiHukum,
  uploadMediaConsultant
} from 'services';
import { Header, Required } from 'component';

const { TextArea } = Input;

export default function AddKonsultasiHukum() {
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    address: '',
    phone: '',
    description: '',
    profile_path: ''
  });

  useEffect(() => {
    if (id) {
      getConsultantData();
    }
  }, []);

  async function getConsultantData() {
    const { data } = await getKonsultasiHukumDetail(id);
    const consutantData = data?.data;
    setFormData(consutantData);
  }

  function validate() {
    if (!formData.name) {
      Modal.error({
        title: 'Error',
        content: 'Nama harus diisi',
        maskClosable: true
      });
    } else if (!formData.phone) {
      Modal.error({
        title: 'Error',
        content: 'Nomor telepon harus diisi',
        maskClosable: true
      });
    } else if (!formData.phone?.startsWith('62')) {
      Modal.error({
        title: 'Error',
        content: 'Nomor telepon harus diawali dengan 62',
        maskClosable: true
      });
    } else if (!formData.address) {
      Modal.error({
        title: 'Error',
        content: 'Alamat harus diisi',
        maskClosable: true
      });
    } else if (!formData.description) {
      Modal.error({
        title: 'Error',
        content: 'Deskripsi harus diisi',
        maskClosable: true
      });
    } else if (!formData.profile_path) {
      Modal.error({
        title: 'Error',
        content: 'Gambar harus diisi',
        maskClosable: true
      });
    } else {
      return true;
    }
  }

  function handleBeforeUpload(file) {
    if (file.type == 'image/jpeg' || file.type == 'image/jpg') {
      if (file.size / 1024 / 1024 <= 2) {
        return false;
      }
      Modal.error({
        title: 'Error',
        content: `Ukuran File lebih besar dari 2 MB`,
        maskClosable: true
      });

      return true;
    } else {
      Modal.error({
        title: 'Error',
        content: `${file.name} tidak bertipe jpg or jpeg `,
        maskClosable: true
      });

      return true;
    }
  }

  async function handleSubmit() {
    setLoading(true);
    console.log('FORM', formData);
    if (validate()) {
      const result = id
        ? await updateKonsultasiHukum(formData, id)
        : await addKonsultasiHukum(formData);
      if (result.status >= 200 && result.status < 300)
        Modal.success({
          title: id ? 'Data berhasil diubah' : 'Pengacara berhasil ditambahkan',
          content: id
            ? 'Data pengacara telah berhasil diubah'
            : `${name} telah berhasil ditambahkan`,
          maskClosable: true,
          afterClose: () => history.push('/konsultasi-hukum')
        });
      else
        Modal.error({
          title: id ? 'Data gagal diubah' : 'Pengacara gagal ditambahkan',
          content: 'Mohon periksa kembali data yang kamu input pada form',
          maskClosable: true
        });
    }
    setLoading(false);
  }

  function handleFormInput(key, value) {
    setFormData((prev) => ({
      ...prev,
      [key]: value
    }));
  }

  return (
    <Layout className="container">
      <Header label={id ? 'Ubah Konsultasi Hukum' : 'Tambah Konsultasi Hukum'} allowBack />
      <Breadcrumb className="breadcrumb-container">
        <Breadcrumb.Item>Kelola Konsultasi Hukum</Breadcrumb.Item>
        <Breadcrumb.Item>
          {id ? 'Ubah Konsultasi Hukum' : 'Tambah Konsultasi Hukum'}
        </Breadcrumb.Item>
      </Breadcrumb>
      <Layout.Content style={{ margin: '20px' }}>
        <div className="form-container">
          <Row gutter={[24, 24]}>
            <Col lg={12}>
              <div className="form-item-container">
                <div className="label">
                  Nama Pengacara <Required />
                </div>
                <div>
                  <Input
                    placeholder="Masukkan nama pengacara.."
                    value={formData.name}
                    onChange={(e) => handleFormInput('name', e?.target?.value)}
                  />
                </div>
              </div>
            </Col>
            <Col lg={12}>
              <div className="form-item-container">
                <div className="label">
                  Nomor Telepon <Required />
                </div>
                <div>
                  <Input
                    placeholder="Masukkan nomor pengacara.."
                    value={formData.phone}
                    onChange={(e) => handleFormInput('phone', e?.target?.value)}
                  />
                </div>
              </div>
            </Col>
            <Col lg={12}>
              <div className="form-item-container">
                <div className="label">
                  Alamat <Required />
                </div>
                <div>
                  <TextArea
                    value={formData.address}
                    placeholder="Masukan alamat pengacara"
                    rows={7}
                    maxLength={255}
                    showCount
                    onChange={(e) => handleFormInput('address', e?.target?.value)}
                  />
                </div>
              </div>
            </Col>
            <Col lg={12}>
              <div className="form-item-container">
                <div className="label">
                  Deskripsi <Required />
                </div>
                <div>
                  <TextArea
                    value={formData.description}
                    placeholder="Masukan deskripsi pengacara"
                    rows={7}
                    maxLength={255}
                    showCount
                    onChange={(e) => handleFormInput('description', e?.target?.value)}
                  />
                </div>
              </div>
            </Col>
            <Col lg={12}>
              <div className="form-item-container">
                <div className="label">
                  Foto Pengacara <Required />
                </div>
                <div>
                  <Upload.Dragger
                    listType="picture"
                    accept=".jpg,.jpeg"
                    maxCount={1}
                    beforeUpload={handleBeforeUpload}
                    onChange={(e) => {
                      uploadMediaConsultant(e.file).then((res) => {
                        if (res?.data?.data) {
                          setFormData((prev) => ({ ...prev, profile_path: res?.data?.data?.path }));
                        }
                      });
                    }}
                    onRemove={() => setFormData((prev) => ({ ...prev, profile_path: '' }))}
                    style={{ width: '300px', height: '300px' }}>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Upload foto pengacara (Max 2MB)</p>
                    <p className="ant-upload-hint">
                      Format .jpg atau .jpeg
                      <br />
                      dimensi yang direkomendasikan 4x6
                    </p>
                  </Upload.Dragger>
                </div>
              </div>
            </Col>
            <Col lg={12}>
              {formData?.profile_path !== '' && (
                <Image src={formData?.profile_path} width={200} height={300} />
              )}
            </Col>
            <Col span={24}>
              <Button type="primary" size="large" loading={loading} onClick={handleSubmit}>
                {id ? 'Update' : 'Simpan'}
              </Button>
              {id && (
                <Button
                  type="text"
                  size="large"
                  onClick={() => history.goBack()}
                  style={{ marginLeft: '1rem', color: '#3F8FE7' }}>
                  BATAL
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </Layout.Content>
    </Layout>
  );
}
