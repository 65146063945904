import { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Checkbox,
  Layout,
  Modal,
  Row,
  Space,
  Typography,
  Tag,
  Carousel,
  Image,
  Avatar
} from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import './styles.scss';

import { Header } from 'component';
import { deleteNews, getNewsDetail, toggleNewsComment } from 'services/ServiceNews';
import {
  CloseOutlined,
  DislikeOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  LikeOutlined
} from '@ant-design/icons/lib/icons';
import moment from 'moment';

export default function DetailProKontra() {
  const { id } = useParams();
  const history = useHistory();
  const [data, setData] = useState();

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    const result = await getNewsDetail(id);
    let currentBannerPath = result?.data?.data?.banner_path || '';
    let formattedBanner = [];
    if (String(currentBannerPath)?.indexOf('[') !== -1) {
      formattedBanner = JSON.parse(currentBannerPath);
    } else {
      formattedBanner = [currentBannerPath];
    }
    const newsData = {
      ...result?.data?.data,
      banners: formattedBanner
    };
    setData(newsData);
  }

  function handleToggleComment() {
    toggleNewsComment(id).then(() => fetchData());
  }

  function openModal() {
    Modal.confirm({
      title: 'Hapus berita ini?',
      content: 'Apakah kamu yakin ingin menghapus berita ini?',
      icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
      onOk() {
        deleteNews(id).then(() => {
          history.push('/pro-kontra');
        });
      }
    });
  }

  return (
    <Layout className="container">
      <Header label={data?.title ?? '-'} />
      <Row>
        <Col span={15}>
          <Layout.Content className="content-container" style={{ width: 703 }}>
            <Space align="center" direction="horizontal" size={'small'} wrap>
              <Tag
                color={data?.category?.color}
                style={{ borderRadius: 12, border: 0, padding: '4px 12px', fontWeight: 600 }}>
                {data?.category?.name ?? '-'}
              </Tag>
              {data?.sub_categories?.map((subCtg) => (
                <Tag
                  key={subCtg?.id}
                  color={subCtg?.color}
                  style={{ borderRadius: 12, border: 0, padding: '4px 12px', fontWeight: 600 }}>
                  {subCtg?.name ?? '-'}
                </Tag>
              ))}
            </Space>
            <Typography.Title level={3} style={{ margin: '1rem 0' }}>
              {data?.title ?? '-'}
            </Typography.Title>
            <Row style={{ margin: '1rem 0' }}>
              <Space>
                <Avatar icon={<UserOutlined />} src={data?.created_by?.profile_picture} size={35} />
                <Col>
                  <div>
                    Penulis{' '}
                    <Typography.Text style={{ color: '#1890FF' }} strong>
                      {data?.created_by?.name}
                    </Typography.Text>
                  </div>
                  <div>{moment(data?.created_at).format('dddd, DD MMMM YYYY - HH.mm')}</div>
                </Col>
              </Space>
              <Col
                flex="auto"
                style={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
                <span>
                  <LikeOutlined /> {data?.total_like ?? 0}
                </span>
                <span style={{ margin: '0 2rem' }}>
                  <DislikeOutlined /> {data?.total_like ?? 0}
                </span>
                <span>
                  <img
                    width={14}
                    height={14}
                    style={{ marginRight: '.25rem' }}
                    src={require('assets/img/comment-icon.png')}
                  />
                  {data?.comments?.length ?? 0}
                </span>
              </Col>
            </Row>
            {Boolean(data?.banners?.length) && (
              <Carousel autoplay dotPosition="bottom" style={{ width: 653 }}>
                {data?.banners?.map((img, index) => (
                  <div key={index}>
                    <Image
                      preview={false}
                      style={{ objectFit: 'cover' }}
                      width={653}
                      height={416}
                      src={img}
                    />
                  </div>
                ))}
              </Carousel>
            )}
            {/* <img
              width="100%"
              style={{ margin: '2rem 0', borderRadius: 10 }}
              src={data?.banner_path}
            /> */}
            <div
              className="news-content"
              dangerouslySetInnerHTML={{
                __html: data?.content ?? ''
              }}
            />
          </Layout.Content>
        </Col>
        <Col lg={9}>
          <Layout.Content className="content-container">
            <Space direction="vertical" size="large">
              <Space size="large">
                <Button
                  type="primary"
                  icon={<EditOutlined />}
                  size="large"
                  htmlType="submit"
                  onClick={() => history.push(`/pro-kontra/update/${id}`)}>
                  Ubah berita
                </Button>
                <Button
                  type="primary"
                  danger
                  icon={<CloseOutlined />}
                  size="large"
                  onClick={() => openModal()}>
                  Hapus berita
                </Button>
              </Space>
              <Checkbox checked={data?.comment_toggle ?? false} onClick={handleToggleComment}>
                Ijinkan komentar
              </Checkbox>
            </Space>
          </Layout.Content>
        </Col>
      </Row>
    </Layout>
  );
}
