import { requestAPI } from './Request';

export function getCategories() {
  return requestAPI.get('/categories');
}

export function addCategory(data) {
  return requestAPI.post(`/categories`, data);
}

export function updateCategory(data) {
  return requestAPI.patch(`/categories/${data.id}`, data);
}

export function deleteCategory(id) {
  return requestAPI.delete(`/categories/${id}`);
}
