import { requestAPI } from './Request';

export function getStructures() {
  return requestAPI.get('/structures');
}

export function getStructure(id) {
  return requestAPI.get(`/structures/${id}`);
}

export function addStructure(data) {
  return requestAPI.post(`/structures`, data);
}

export function updateStructure(data) {
  return requestAPI.post(`/structures/${data.id}`, data);
}

export function deleteStructure(id) {
  return requestAPI.delete(`/structures/${id}`);
}
