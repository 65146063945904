import {
  ExclamationCircleOutlined,
  PlusOutlined,
  SearchOutlined
} from '@ant-design/icons/lib/icons';
import { Breadcrumb, Button, Input, Layout, Row, Space, Table, DatePicker, Modal } from 'antd';
import { Header } from 'component';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { deleteNews, getNews } from 'services/ServiceNews';
import { DEFAULT_CATEGORIES_ID } from 'constants';

import './styles.scss';

export default function ManageProKontra() {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState({
    title: '',
    start_date: undefined,
    end_date: undefined
  });
  let debounce = undefined;

  const [data, setData] = useState();

  useEffect(() => {
    debounce = setTimeout(() => {
      fetchData();
    }, 300);

    return () => clearTimeout(debounce);
  }, [page, limit, filter]);

  async function fetchData() {
    const result = await getNews({
      page,
      limit,
      category_id: DEFAULT_CATEGORIES_ID.pro_kontra,
      ...filter
    });
    setData(result.data?.data);
  }

  function openModal(id) {
    Modal.confirm({
      title: 'Hapus berita ini?',
      content: 'Apakah kamu yakin ingin menghapus berita ini?',
      icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
      onOk() {
        deleteNews(id).then(() => {
          fetchData();
        });
      }
    });
  }

  const columns = [
    {
      title: 'Judul',
      dataIndex: 'title',
      key: 'title',
      width: '45%'
    },
    {
      title: 'Kunjungan',
      dataIndex: 'total_visit',
      key: 'total_visit'
    },
    {
      title: 'Likes',
      dataIndex: 'likes',
      key: 'likes',
      render: (value) => <>{value ?? 0}</>
    },
    {
      title: 'Dislikes',
      dataIndex: 'dislikes',
      key: 'dislikes',
      render: (value) => <>{value ?? 0}</>
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'action',
      width: '250px',
      render: (id) => (
        <Space size="small">
          <Button type="primary" onClick={() => history.push(`/pro-kontra/${id}`)}>
            Lihat
          </Button>
          <Button type="primary" danger onClick={() => openModal(id)}>
            Hapus
          </Button>
        </Space>
      )
    }
  ];

  return (
    <Layout className="container">
      <Header label="Kelola Berita Pro Kontra" />
      <Breadcrumb className="breadcrumb-container">
        <Breadcrumb.Item>Kelola Berita Pro Kontra</Breadcrumb.Item>
      </Breadcrumb>
      <Layout.Content className="content-container">
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Row>
            <Input
              style={{ width: '25%', marginRight: '2rem' }}
              placeholder="Cari judul berita..."
              prefix={<SearchOutlined />}
              onChange={(e) => setFilter({ ...filter, title: e.target.value })}
            />
            <DatePicker.RangePicker
              placeholder={['Dari tanggal...', 'Hingga tanggal...']}
              onChange={(_, string) => {
                setFilter({ ...filter, start_date: string[0], end_date: string[1] });
              }}
            />
            <Button
              style={{ marginLeft: 'auto', color: '#1890FF', borderColor: '#1890FF' }}
              icon={<PlusOutlined />}
              onClick={() => history.push('/pro-kontra/add')}>
              Tambah berita
            </Button>
          </Row>
          <Table
            columns={columns}
            pagination={{
              position: ['bottomLeft'],
              current: page,
              pageSize: limit,
              total: data?.total ?? 0,
              onChange: (page) => setPage(page),
              onShowSizeChange: (x, size) => setLimit(size)
            }}
            dataSource={data?.data ?? []}
          />
        </Space>
      </Layout.Content>
    </Layout>
  );
}
